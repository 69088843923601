.accounts-wrapper {
  @include d-flex-center;
  height: 100vh;
  min-height: 600px;
  width: 100%;
  background-image: url('../../Assets/Images/Login/bg-login.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 1rem;
  color: $c-white;
  .accounts-content-wrapper {
    @include d-flex-column-center;
    max-width: 445px;
    width: 100%;
    min-height: 374px;
    background-color: rgba(0, 0, 0, 0.32);
    border-radius: 1rem;
  }
}
