.Review-Comment{
    border-radius: 20px;
    border: solid 1px #e8e8e8;
    background-color: var(--white);
    margin-top: 20px;
    width: 100%;
 
    .user-info{
        display: flex;
        padding: 0 10px;
        .profile{
            margin: 10px 20px 0 20px;
            width: 50px;
            height: 50px;
            border-radius: 30px;
            .profile-img{
                width: 100%;
                height: 50px;
              
            }
        }
        .name-rate{
            .name{
                font-family: LibreFranklin;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                margin: 10px 15px 0 0;
                color: #151517;
            }
            .rate{
                .MuiBox-root.MuiBox-root{
                  margin-bottom: 1px ;
                }
             }

        }
    }
    .user-comment{
     
            font-family: LibreFranklin;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #151517;
            text-align: left;
            font-size: 12px;
            margin: 10px 20px 0 20px;
            padding: 0 10px;
        
    }
    .comment-location{
        margin:  20px;
        padding: 0 10px;
        text-align: left;
    }
}