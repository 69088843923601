.services-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #fafafa;
  display: flex;
  @include tablets-max {
    display: block;
  }
  justify-content: space-evenly;
  align-items: flex-start;
  .item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include tablets-max {
      padding-bottom: 5rem;
    }
    .item-title {
      font-weight: bold;
      font-size: 22px;
      color: #333333;
      text-align: center;
      padding-bottom: 0.3rem;
    }
    .item-description {
      font-size: 14px;
      color: #3333337f;
      width: 25rem;
      text-align: center;
      @include laptops-max {
        width: 18rem;
      }
      @include tablets-max {
        width: 25rem;
      }
      @include phones-max {
        width: 20rem;
      }
      @include phones-xs-max {
        width: 18rem;
      }
    }
    .item-image {
      padding-bottom: 1rem;
      .cancelation-size {
        width: 60px;
        height: 60px;
      }
      .price-size {
        width: 60px;
        height: 60px;
      }
      .service-size {
        width: 60px;
        height: 60px;
      }
    }
  }
}
