@import "../../../../../Assets/Style/Helpers/Helpers.Style.scss";
.FavoritDetailsWrapper {
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 78%;
  margin: auto;

  @include tablets-max {
    width: 83%;
  }
  @include laptops-max {
    width: 90%;
  }
  @media screen and (max-width: 1275px) {
    width: 95%;
  }
  @include laptops-l-max {
    width: 90%;
  }
  @include laptops-xl-max {
    width: 77%;
  }

  @include laptops-max {
    width: 95%;
  }
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 78%;
  margin: auto;

  .seeAll-button {
    font-size: 2.125rem;
    color: $c-default-dark;
    font-weight: bolder;
  }
  .description-button {
    width: 94%;
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 2.125rem;
    color: $c-default-dark;
    font-weight: bolder;
    .description {
      @include phones-max {
        width: 50%;
        font-size: 16px;
        display: flex;
        margin: left;
        margin-left: 10px;
      }
    }
    .AddToCard {
      display: flex;
      justify-content: space-between;
      width: 18%;
      height: 38px;
      @include laptops-max {
        width: 30%;
      }
      @include phones-max {
        width: 48%;
      }
      .AddToCardButton {
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        border-radius: 1rem;
        padding: 0.25rem 0.5rem;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        white-space: nowrap;
        min-height: 30px;
        background-image: linear-gradient(
          172deg,
          #ff7f18 -55%,
          #ff1364 174%,
          #580dbf 439%
        );
      }
    }
  }
  .titleButtons {
    .more-details {
      button {
        line-height: 1.84;
        opacity: 0.85;
        font-weight: bolder;
        font-size: 1rem;
        font-weight: bold;
        color: $c-default-dark;
      }

      display: flex;
      width: 50%;
      justify-content: space-around;
      @include phones-max {
        width: 100%;
      }
    }
  }
   .titleButtons {
    .more-details {
      button {
        line-height: 1.84;
        opacity: 0.85;
        font-weight: bolder;
        font-size: 1rem;
        font-weight: bold;
        color: $c-default-dark;
      }

      display: flex;
      width: 50%;
      justify-content: space-around;
      @include phones-max {
        width: 100%;
      }
    }
  }

  .tour-details {
    margin-top: 20px;
    .btt-price-details {
      width: 100px;
      height: 30px;
      background-color: transparent;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: inherit;
      color: $Orange;
      border: 1px solid $Orange;
      border-radius: 30px;
      appearance: button !important;
      cursor: pointer;
    }
    .tour-details-phone {
      position: relative;
      .more-Images-phone {
        z-index: 100;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        @include rtl {
          right: initial;
          left: 1.5rem;
        }
        background: #00000081 0% 0% no-repeat padding-box;
        border-radius: 30px;
        width: 80px;
        height: auto;
        z-index: 100;
        display: flex;
        justify-content: space-evenly;
        span {
          color: white;
          margin: auto;
        }

        @include rtl {
          margin-right: 40px;
        }
      }
      display: none;
      @include phones-max {
        display: block;
      }

      .div-1-phone {
        width: 100%;
        .price-details-phone {
          width: 100%;
          height: 50%;
          .price-contenar-phone {
            .btt-price-details-phone {
              width: 100px;
              height: 30px;
              background-color: transparent;
              font-size: 0.75rem;
              font-weight: 600;
              text-transform: inherit;
              color: $Orange;
              border: 1px solid $Orange;
              border-radius: 30px;
              appearance: button !important;
              cursor: pointer;
            }
            width: 70%;
            display: flex;
            justify-content: space-around;
            text-align: left;
            padding: 10px;

            .from-price-phone {
              font-size: 26px;
              width: 60%;
              font-family: Poppins;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.98;
              letter-spacing: normal;
              text-align: left;
              color: #ff5e0d;
            }
          }
          .details-phone {
            color: #2b0649;
            display: flex;
            justify-content: space-around;
            .details-Container-phone {
              display: flex;
              justify-content: space-around;
              margin: 0px 15px 10px 10px;
              span {
                white-space: nowrap;
                color: #2b0649;
                margin-top: 20px;
              }
            }
          }
        }
      }
      .div-2-phone {
        display: flex;
        width: 100%;
        .big-img-phone {
          height: 50%;
          width: 100%;
          display: block;
          padding: 10px;
          .big-img-one-phone {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-left: 23px;
            object-fit: cover;
            object-position: 50% 70%;
            @include laptops-max {
              margin-left: 0px;
            }
          }
        }
        .small-img-phone {
          height: 30%;
          width: 100%;
          display: block;
          padding: 10px;
          .small-img-one-phone {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 70%;
            border-radius: 10px;
            margin-left: 23px;
            @include laptops-max {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .tour-details-grid {
      position: relative;
      .more-Images {
        z-index: 100;
        position: absolute;
        bottom: 1.5rem;
        right: 5.5rem;
        @include rtl {
          right: initial;
          left: 5.5rem;
        }
        background: #00000081 0% 0% no-repeat padding-box;
        border-radius: 30px;
        width: 80px;
        height: auto;
        z-index: 100;
        display: flex;
        justify-content: space-evenly;
        span {
          color: white;
          margin: auto;
        }

        @include rtl {
          margin-right: 40px;
        }
      }
      
      display: flex;
      height: 650px;
      @include phones-max {
        display: none;
      }
      .div-1-container {
        width: 65%;
        display: flex;
      }
      .div-1 {
        width: 50%;
        @include phones-max {
          width: 50%;
        }
      }
      .div-2 {
        position: relative;
        width: 30%;
        @include phones-max {
          width: 100%;
          display: flex;
        }
      }
      .small-img {
        img[alt]:after {
          display: none;
        }
        height: 30%;
        width: 100%;
        display: block;
        padding: 10px;
        .small-img-one {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: 50% 70%;
          @include laptops-max {
            margin-left: 0px;
          }
        }
      }
      .big-img {
        position: relative;
        height: 70%;
        width: 100%;
        display: block;
        padding: 10px;
        .big-img-one {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: 50% 70%;
          @include laptops-max {
            margin-left: 0px;
          }
        }
      }
    }

    .price-details {
      margin-left: 23px;
      height: 30%;
      display: block;
      text-align: justify;
      padding: 10px;
      .price-contenar {
        display: flex;
        .from-price {
          font-family: Poppins;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.98;
          letter-spacing: normal;
          text-align: left;
          @include rtl{
            text-align: right;
            margin-right: 20px;
          }
          color: #ff5e0d;
          @include laptops-max {
            font-size: 17px;
          }
          font-size: 26px;
          width: 60%;
        }
      }
      .details {
        display: flex;
      }

      .details-Container {
        display: grid;
        margin: 0 30px 10px 0;
        span {
          white-space: nowrap;
          color: #2b0649;
          margin-top: 20px;
        }
      }
      @include laptops-max {
        margin-left: 0px;
      }
    }
  }
  .Itinerary {
    display: flex;
    width: 100%;
    height: 450px;
    @include phones-max {
      display: inline;
    }
    .Itinerary-description {
      margin: auto;
      width: 30%;
      @include phones-max {
        width: 90%;
      }
      .iotinerary-titel {
        color: $c-default-dark;
        font-size: 30px;
        padding: 10px;
      }
      .itinerary-description {
        color: $c-default-dark;
        font-size: 13px;
        padding: 10px;
      }

      .itinerary-button {
        width: 215px;
        margin-top: 10px;
        margin-bottom: 60px;
        display: inline-flex;
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        border-radius: 1rem;
        padding: 0.25rem 0.5rem;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        white-space: nowrap;
        min-height: 30px;
        background-image: linear-gradient(
          172deg,
          #ff7f18 -55%,
          #ff1364 174%,
          #580dbf 439%
        );
      }
    }
    .UAE {
      margin: auto;
      width: 30%;
      @include phones-max {
        width: 90%;
      }
      .img-div {
        text-align: center;
        img {
          width: 70%;
        }
      }

      .itinerary-button-div {
        text-align: center;
        .itinerary-btn {
          flex: 0 1 auto;
          align-items: center;
          justify-content: center;
          min-width: 100px;
          border-radius: 1rem;
          padding: 0.25rem 0.5rem;
          font-weight: 500;
          font-size: 1rem;
          color: #fff;
          white-space: nowrap;
          min-height: 30px;
          background-image: linear-gradient(
            172deg,
            #ff7f18 -55%,
            #ff1364 174%,
            #580dbf 439%
          );
        }
      }
    }
  }
  .place-name {
    display: flex;
    position: absolute;
    background: #00000081 0% 0% no-repeat padding-box;
    border-radius: 30px;
    margin-left: 40px;
    margin-top: 15px;
    width: auto;
    max-width: 130px;
    min-height: 25px;
    z-index: 10;
    height: auto;
    color: white;
    font-size: 10px;
    padding-left: 13px;
    padding-right: 11px;
    padding-top: 2.5px;
    @include rtl {
      margin-right: 40px;
    }
  }
  .cards {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    .line-map {
      margin-top: 55px;
      position: relative;
    }
    .line {
      @include iPad {
        display: none;
      }
      width: 740px;
      position: absolute;
      text-align: center;
      left: 4px;
      z-index: 1;
      @include rtl {
        right: 4px;
      }
      img {
        width: 50%;
      }
      span {
        display: block;
        color: #2b0649;
      }
    }
  }

  .about-tour {
    width: 50%;
    margin: auto;
    @include phones-max {
      width: 90%;
    }
    .about-title {
      color: #580dbf;
      font-size: 30px;
      margin: 25px 0 25px 0;
    }
    .about-description {
      margin: 25px 0 25px 0;
      color: #2b0649;
      font-size: 18px;
      width: 100%;
    }
    .About-component {
      .description-div {
        margin: 10px;
        font-size: 18px;
        color: #2b0649;
      }
      .div-collapse {
        margin: 20px 0;
        .tripName {
          color: #580dbf;
          font-weight: bold;
          font-size: 18px;
          margin: 10px;
        }
        span {
          display: flex;
          font-size: 15px;
          color: #2b0649;
          margin: 0 0 0 8px;
        }
      }
    }
    .about-dropdown {
      .MuiFormControl-root.select-wrapper.select-filled {
        width: 100%;
      }
      .MuiFilledInput-input {
        padding: 0;
      }
      .select-wrapper.MuiFormControl-root .select-body-wrapper .selects {
        min-height: 0;
        border: 0;
      }
    }
  }
  .big-space {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 150px;
  }
  .Reviews {
    text-align: center;
    width: 50%;
    margin: auto;
    @include phones-max {
      width: 90%;
    }
    .Reviews-title {
      text-align: center;
      color: #580dbf;
      font-size: 30px;
      margin: 25px 0 25px 0;
    }
    .Reviews-description {
      margin: 25px 0 25px 0;
      color: #2b0649;
      font-size: 18px;
      width: 100%;
    }
    .Reviews-comments {
      margin-top: 20px;
    }
  }
  .AddToCard {
    display: flex;
    justify-content: space-between;
    width: 18%;
    height: 38px;
    .AddToCardButton {
      flex: 0 1 auto;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      border-radius: 1rem;
      padding: 0.25rem 0.5rem;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      white-space: nowrap;
      min-height: 30px;
      background-image: linear-gradient(
        172deg,
        #ff7f18 -55%,
        #ff1364 174%,
        #580dbf 439%
      );
    }
  }
}
