.VerificationCode-wrapper {
  max-height: 371px;
  .input-number {
    .input-wrapper .text-field-wrapper .inputs {
      width: 100%;
      display: flex;
      flex: initial;
      justify-content: center;
      width: 43px !important;
      height: 58px !important;
      padding: 0 !important;
    }
    .input-wrapper.theme-dark .text-field-wrapper .inputs .MuiInputBase-input {
      color: #fff;
      text-align: center;
    }
    .input-wrapper.theme-dark .text-field-wrapper .inputs .MuiInputBase-root {
      border: 1px solid #ffffff;
      border-radius: 10px;
      background-color: #3b244740;
    }
 
    .input-wrapper {
      display: inline-flex;
      flex: 0 1 auto;
      align-items: center;
      width: auto;
    }
    .MuiInputBase-input {
      color: #fff;
      text-align: center;
      font-size: 30px !important;
      opacity: 100%;
    }
  }
  @include d-flex-column-v-between;
  width: 100%;
  .login-header {
    @include d-flex-column;
    padding: 1rem;
    .title-text {
      opacity: 1;
      font-size: $fz-24px;
      color: $c-white;
    }
  }
  .MuiInputBase-input,
  span {
    opacity: 0.5;
  }

  .login-body {
    padding: 1rem;
    .body-item-number-input {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 0.5rem;
    }
    .body-item {
      @include d-flex-center;
      margin-bottom: 0.5rem;
    }
  }
  .login-footer {
    @include d-flex;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    .footer-item {
      @include d-inline-flex-center;
      width: 33.3333%;
      min-height: 45px;
      border-top: 1px solid $c-default-dark-opacity !important;
      &:not(:last-child) {
        border-right: 1px solid $c-default-dark-opacity;

        @include rtl {
          border-left: 1px solid $c-default-dark-opacity;
          border-right: none;
        }
      }
      @include phones-max {
        width: 50%;
        &:nth-child(even) {
          border: none;
        }
        &:last-child {
          width: 100%;
          border: none;
        }
      }
      .btns {
        width: 100%;
        height: 100%;
      }
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
  position: relative;
  .outside-login {
    position: absolute;
    top: 100%;
    @include d-flex-column;
    width: 100%;
    padding: 0.5rem 1rem;
    .outside-item {
      @include d-inline-flex-center;
      @include phones-max {
        flex-direction: column;
      }
    }
    .opacity-1 {
      opacity: 1;
    }
  }
}
