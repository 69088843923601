.Pagination-UiComponentes {
  .MuiPaginationItem-page {
    color: #ff5e0d;
    border: 1px solid #ffddc4;
    width: 24px;
    height: 24px;
  }
  .MuiPaginationItem-sizeLarge {
    height: 24px;
    padding: 0 10px;
    font-size: 0.9375rem;
    min-width: 24px;
    border-radius: 20px;
  }
  .MuiPaginationItem-textPrimary.Mui-selected {
    color: #ff5e0d;
    background-color: #fff;
    width: 58px;
    height: 30px;
  }
  .MuiPaginationItem-sizeLarge {
    height: 24px;
    color: #ff5e0d;
    padding: 0 10px;
    font-size: 0.9375rem;
    padding: 0px;
    min-width: 24px;
    border: 1px solid #ffddc4 !important;
    border-radius: 20px;
  }
  .MuiPaginationItem-textPrimary.Mui-selected:hover,
  .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
    background-color: #ff5e0d;
    color: white;
    border: 1px solid white !important;
  }
  .MuiButtonBase-root.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: fill; 
  }
}
