// ======================================================== Start Custom Themes
@import './Utilities/Buttons.Style.scss';
@import './Utilities/Scrollbar.Style.scss';
// ======================================================== End Custom Themes
//========================================================= Start Main Tags Override
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  outline: 0 !important;
}

body::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid transparent;
  border-radius: 10rem;
}

body {
  overflow-y: auto;
  margin: 0;
  font-family: $ff-primary;
  min-height: 100vh;
  min-width: 320px;
  font-size: $fz-primary;
  font-weight: $fw-primary;
  line-height: 1.5;
  color: $c-primary;
  background-color: $c-white;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &.rtl {
    text-align: right;
  }
}
//========================================================= End Main Tags Override
// ======================================================== Start Global Override
.MuiTypography-body1,
.MuiButtonBase-root,
.MuiFormControl-root,
.MuiSelect-root,
.autocomplete,
.MuiFormLabel-root,
.MuiChip-label,
.MuiInputBase-input,
.MuiTableCell-root,
.MuiTypography-root {
  font-family: $ff-primary;
  font-size: $fz-primary;
}
.mdi {
  line-height: 100%;
  &:before {
    line-height: 100%;
    font-size: $fz-19px;
  }

  &.mdi-chevron-right,
  &.mdi-chevron-left,
  &.mdi-menu-right,
  &.mdi-menu-left,
  &.mdi-chevron-double-right {
    @include rtl {
      transform: scale(-1);
    }
  }

  &.mdi-magnify {
    @include rtl {
      transform: rotateZ(90deg);
    }
  }
}
.loadable-skeleton {
  &.MuiSkeleton-text {
    transform: scale(1);
  }
}
// ======================================================== End Global Override
// ======================================================== Start Borders
.br-0 {
  border-radius: 0 !important;
}
// ======================================================== End Borders
// ======================================================== Start Position
.p-relative {
  position: relative !important;
}
// ======================================================== End Position
// ======================================================== Start Fonts
.fw-bold {
  font-weight: bold;
}
// ======================================================== End Fonts
// ======================================================== Start Display
@import './Utilities/Display.Style.scss';
// ======================================================== End Display
// ======================================================== Start Widths
@include widths();
// ======================================================== End Widths
// ======================================================== Start Heights
@include heights();
// ======================================================== End Heights
// ======================================================== Start Margins & Padding
@include padding-marin-loop;
// ======================================================== End Margins & Padding
// ======================================================== Start Colors
@include colors-loop;
// ======================================================== End Colors
.show {
  display: block;
}
.hidden {
  display: none;
}