
.gallery-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem;
  .gallery-item-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    .gallery-item-box {
      height: 100px;
      width: 100px;
      max-width: 100px;
      max-height: 100px;
     
    
    
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      .gallery-image {
        width: 100%;
        height: 100%;
        &.is-default {
          width: 40px;
          height: 40px;
        }
      }
    }
    .gallery-text {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
}
.facebook-gallery-active-image{
  width: 90%;
  margin: auto;
        } 
        
.facebook-gallery-thumbnail-wrapper{
  :-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
  width: 80%;
    margin: auto;
}
.facebook-gallery-thumbnail-items-wrapper{
  display: flex;
  .facebook-gallery-thumbnail-item{
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
  }
}
.facebook-gallery-thumbnail-image{
width: 150px;
height: 100px;
object-fit: cover;
}
.d-flex-center{
  margin-top: 10px;
}
.cancel-button{
  margin-bottom: 10px;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  min-height: 30px;
  background-image: linear-gradient(172deg, #ff7f18 -55%, #ff1364 174%, #580dbf 439%);
}


  .dialog-title-text-Gallery {
    color:#ff5e0d ;
    border-bottom: 1px solid #ff5e0d;
    font-size: 16px;
    padding: 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
  }
  .closeClasses-Gallery{
    color:#ff5e0d ;
  }

