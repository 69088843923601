@import '../../../../Assets/Style/Helpers/Variables/Variables.Style.scss';
.CreateTourWrapper {
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  .TourCardComponentes-contaner-wrpaer {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 80rem;
    justify-content: center;
  }
  .CreateTour-Seaction {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem 1rem 2rem 1rem;
    justify-content: space-evenly;

    .filter {
      width: 272px;
      height: 579px;
      border: 1px solid #d0d0d0;
      border-radius: 10px;
    }
  }
}

.TourCardComponentes {
  padding: 5px;

  .TourCardComponentesWrapper {
    width: 244px;
    height: 238px;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 20px;
    font-size: 12px;
    color: #2b0649;
    .Card-Header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      .btns-icons.MuiButtonBase-root {
        color: #2b0649 !important;
        background-color: #f5f5f547;
      }
    }
    .Image-container-TourCardComponentes {
      display: flex;
      justify-content: center;
      padding-bottom: 11px;
      .btns-add {
        color: #580dbf;
        position: absolute;
        background-color: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        top: 78%;
        box-shadow: 3px 3px 12px #0000004f;
      }
      .TourComponentesImage {
        width: 164px;
        height: 141px;
        box-shadow: 3px 3px 12px #0000004f;
        border-radius: 10px;
      }
    }
    .title {
      width: 100%;
      height: 20px;
      font-size: 14px;
      padding-bottom: 2rem;
      font-weight: bold;
    }
    .footer-card {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.SelectedTourCardComponentes {
  width: 272px;
  margin-top: 5px;
  height: 680px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  .TourCards-conteaner {
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .Card-title {
    width: 100%;
    height: 38px;
    color: white;
    font-size: 14px;
    background: #ff5e0d;
    font-size: 14px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding: 1rem;
  }
  .Card-Data {
    width: 100%;
    justify-content: center;
    display: inline-block;
    padding-bottom: 1rem;
    .item-Data-1 {
      color: #2b0649;
      font-size: 12px;
      align-items: center;
      display: flex;
      font-weight: bolder;
      justify-content: space-evenly;
    }
    .item-Data-2 {
      color: #ff5e0d;
      font-size: 18px;
      padding-bottom: 0.6rem;
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 0.8rem;
      font-weight: bolder;
    }
  }

  .TourCardComponentesWrapper {
    margin-top: 12px;
    width: 221px;
    height: 169px;
    border: 2px solid #ff5e0d;
    border-radius: 10px;
    padding: 20px;
    font-size: 12px;
    color: #2b0649;

    .Card-Header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      .btns-icons.MuiButtonBase-root {
        color: #2b0649 !important;
        background-color: #f5f5f547;
      }
    }

    .Image-container-TourCardComponentes {
      display: flex;
      justify-content: center;
      padding-bottom: 11px;
      .btns-remove {
        color: #580dbf;
        position: absolute;
        background-color: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        top: 57%;
        box-shadow: 3px 3px 12px #0000004f;
      }
      .TourComponentesImage {
        width: 122px;
        height: 71px;
        box-shadow: 3px 3px 12px #0000004f;
        border-radius: 10px;
      }
    }
    .title {
      width: 100%;
      height: 20px;
      font-size: 14px;
      padding-bottom: 2rem;
      font-weight: bold;
    }
    .footer-card {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.wallpaper-create {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
  border-bottom-left-radius: 7rem;
  color: #fff;
  margin-top: 3.5rem;
  filter: blur(1px);
}
.header-img {
  display: flex;
  flex: 1 1 100%;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7rem;
  height: 230px;
  min-height: 10rem;
  width: 100%;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 1rem;
  color: #fff;
  .wallpaper-text {
    font-size: 60px;
    font-weight: bolder;
  }
  .wallpaper-sub-text {
    font-size: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
