.date-range-picker-wrapper {
  @include d-flex-column;
  width: 100%;
}
.date-range-picker {
  .rdrStaticRangeLabel,
  .rdrInputRangeInput,
  .rdrInputRange,
  .rdrInputRanges,
  .rdrStaticRangeLabel,
  .rdrDateRangeWrapper,
  .rdrWeekDay,
  .rdrMonthName,
  .rdrDay:not(.rdrDayPassive) .rdrDayNumber span,
  select,
  .rdrDateDisplayItem input {
    color: $c-default-dark;
  }
  select {
    background: none;
  }
  .rdrDateDisplayItemActive {
    border-color: $c-default-dark;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    position: relative;
    &::before {
      content: '\F0140';
      font-family: 'Material Design Icons';
      font-size: $fz-22px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $c-default-dark;
      right: 0.25rem;
      @include rtl {
        left: 0.25rem;
        right: initial;
      }
    }
  }
  .rdrNextPrevButton {
    position: relative;
    background-color: transparent;
    width: 33px;
    height: 33px;
    border-radius: 0.5rem;
    i {
      display: none;
    }
    &::before {
      font-family: 'Material Design Icons';
      font-size: $fz-22px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $c-default-dark;
      left: 50%;
    }
  }
  .rdrNextButton {
    &::before {
      content: '\F0142';
    }
  }
  .rdrPprevButton {
    &::before {
      content: '\F0141';
    }
  }
  .rdrDay {
    color: $c-default-dark !important;
    border-radius: 2rem;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background-color: $c-default-dark;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: $c-primary;
  }
  .rdrInRange {
    background-color: rgba($c-primary, 0.5);
  }
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayInPreview + .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrInRange + .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrInRange + .rdrDayInPreview + .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayInPreview + .rdrDayNumber span {
    color: $c-white;
  }
  .rdrDay:not(.rdrDayPassive) .rdrDayInPreview {
    border-color: $c-default;
  }
  .rdrDay.rdrDayDisabled {
    // border-color: #c6c6c6;
    background-color: transparent;
    .rdrDayNumber span {
      color: #c6c6c6 !important;
    }
  }
  &.theme-dark {
    .rdrDefinedRangesWrapper,
    .rdrCalendarWrapper,
    .rdrStaticRange,
    .rdrDateDisplayWrapper {
      background-color: $c-default-dark;
    }

    .rdrStaticRangeLabel,
    .rdrInputRangeInput,
    .rdrInputRange,
    .rdrInputRanges,
    .rdrStaticRangeLabel,
    .rdrDateRangeWrapper,
    .rdrWeekDay,
    .rdrMonthName,
    .rdrDay:not(.rdrDayPassive) .rdrDayNumber span,
    select,
    .rdrDateDisplayItem input {
      color: $c-white;
    }
    .rdrDateDisplayItemActive {
      border-color: $c-white;
    }
    .rdrMonthPicker,
    .rdrYearPicker {
      &::before {
        color: $c-white;
      }
    }
    .rdrNextPrevButton {
      &::before {
        color: $c-white;
      }
    }
    .rdrDateDisplayItem,
    .rdrInputRangeInput {
      background-color: $c-default-dark-opacity;
    }
    .rdrDay:not(.rdrDayPassive) .rdrDayInPreview {
      border-color: $c-white;
    }
  }
}
