.input-wrapper {
  @include d-inline-flex-v-center;
  //   flex-wrap: wrap;
  width: 100%;
  &.MuiFormControl-root {
    margin-bottom: 0.5rem;
  }
  .label-wrapper {
    padding: 0 0.5rem 0.25rem;
    color: $c-default-dark;
    width: 100%;
  }

  &.with-start-andorment {
    .MuiInputBase-root > .mdi {
      color: $c-default-dark;
      margin-left: 0.5rem;
      @include rtl {
        margin-right: 0.5rem;
        margin-left: initial;
      }
    }
    &.theme-dark {
      .MuiInputBase-root > .mdi {
        color: $c-white;
      }
    }
  }
  .over-input-wrapper {
    @include d-inline-flex;
    color: $c-default-dark;
    position: absolute;
    z-index: 1;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    @include rtl {
      left: initial;
      right: 0.5rem;
    }
  }
  .text-field-wrapper {
    @include d-flex-v-center;
    width: 100%;
    .before-icon-classes-wrapper,
    .after-icon-classes-wrapper {
      margin: 0 0.5rem;
    }
    .inputs {
      width: 100%;
      @include d-flex-h-center;
      padding: 0 !important;
      .MuiInputBase-root {
        min-height: 35px;
        width: 100%;
        background-color: #fafafa;
        border: 1px solid #f0f0f0;
        color: $c-default-dark;
        border-radius: 2rem;
        @include d-flex-center;
        flex-wrap: nowrap;
        // &.Mui-focused {
        //   // border-radius: 1rem;
        // }
      }
      .MuiInputBase-input {
        color: $c-default-dark;
        font-size: $fz-14px;
        width: 100%;
        padding: 0.25rem 0.5rem !important;
        &::placeholder {
          color: $c-default-dark;
          opacity: 0.5;
        }
      }
    }
  }
  &.theme-dark {
    .label-wrapper {
      color: $c-white;
    }
    .over-input-wrapper {
      color: $c-white;
    }
    .text-field-wrapper {
      .inputs {
        .MuiInputBase-root {
          background-color: $c-default-dark-opacity;
          border: 1px solid $c-default-dark-opacity;
        }
        .MuiInputBase-input {
          color: $c-white;
          &::placeholder {
            color: $c-white;
            opacity: 0.5;
          }
        }
      }
    }
  }
  &.theme-default-dark {
    .over-input-wrapper {
      color: $c-white;
    }
    .text-field-wrapper {
      .inputs {
        .MuiInputBase-root {
          background-color: $c-default-dark-opacity;
          border: 1px solid $c-default-dark-opacity;
          color: $c-white;
          > .mdi {
            color: $c-white;
          }
        }
        .MuiInputBase-input {
          color: $c-white;
          &::placeholder {
            color: $c-white;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
  &.theme-underline {
    .text-field-wrapper {
      .inputs {
        .MuiInputBase-root {
          border-bottom: 1px solid $c-default-dark;
        }
      }
    }
  }
  &.theme-underline,
  &.theme-transparent {
    .text-field-wrapper {
      .inputs {
        .MuiInputBase-root {
          background-color: transparent;
          border-color: transparent;
          border-radius: 0;
        }
        .MuiInputBase-input {
          color: $c-default-dark;
        }
      }
    }
    &.theme-dark {
      .text-field-wrapper {
        .inputs {
          .MuiInputBase-root {
            border-bottom-color: #909090;
          }
          .MuiInputBase-input {
            color: $c-white;
          }
        }
      }
    }
  }
  .Mui-error {
    &.MuiInputBase-root {
      border-color: $c-error !important;
      .mdi {
        color: $c-error !important;
      }
      .MuiInputBase-input {
        color: $c-error !important;
        &::placeholder {
          color: $c-error !important;
        }
      }
    }
  }
  .MuiFormHelperText-root {
    padding: 0 1rem;
  }
}
