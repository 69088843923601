.footer-wrapper {
  background-color: #f8f8f8;
  border-radius: 0 100px 0 0;
  @include rtl {
    border-radius: 100px 0 0 0;
  }
  padding-bottom: 1rem;
  padding-top: 1rem;
  @include laptops-xxl-max {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  @include laptops-xl-max {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @include laptops-l-max {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @include tablets-max {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @include phones-max {
    padding-left: 0;
    padding-right: 0;
  }
  .footer-logo {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    .content-img {
      width: 76px;
      margin-bottom: 2rem;
    }
  }
  .footer-content {
    padding: 2rem;
    padding-top: 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    @include tablets-max {
      display: block;
      justify-content: space-between;
    }
    .left-section {
      width: 30%;
      @include tablets-max {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      @include phones-max {
        width: 100%;
        display: contents;
      }
      .top-content {
        @include tablets-max {
          width: 45%;
        }
        @include phones-max {
          width: 100%;
        }
        .content-title {
          color: #1a002f;
          padding-bottom: 1rem;
          font-weight: bold;
        }
        .content-description {
          color: #8d8d8d;
        }
        padding-bottom: 3rem;
      }
      .bottom-content {
        @include tablets-max {
          width: 45%;
        }
        @include phones-max {
          width: 100%;
          padding-bottom: 2rem;
        }
        .contact-us {
          .get-in-touch {
            font-size: 14px;
            font-weight: bold;
            color: #1a002f;
            padding-bottom: 1rem;
          }
          .get-description {
            font-size: 12px;
            color: #8d8d8d;
          }
          .MuiTextField-root {
            margin-right: 1rem;
            padding-left: 1rem;
            padding-right: 3rem;
            @include rtl {
              padding-left: 3rem;
              padding-right: 1rem;
              margin-right: 0;
              margin-left: 1rem;
            }
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            background-color: white;
            border-radius: 20rem;
          }
          .MuiOutlinedInput-input {
            background-color: white;
            border-radius: 20rem;
          }
          .MuiInput-underline:hover:after {
            border: 0 solid transparent;
          }
          .MuiInput-underline:after {
            border: 0 solid transparent;
          }
          .MuiInput-underline:hover:before {
            border: 0 solid transparent;
          }
          .MuiInput-underline:before {
            border: 0 solid transparent;
          }
          .MuiButton-root {
            text-transform: capitalize;
          }
          .contact-field {
            padding-top: 1rem;
            display: flex;
            align-items: center;
            align-content: center;
            .MuiButtonBase-root {
              &:disabled {
                @include d-inline-flex-center;
                min-width: 100px;
                border-radius: 20rem;
                font-weight: $fw-bold;
                color: #fff;
                white-space: nowrap;
                cursor: not-allowed !important;
                border-color: #c6c6c6;
                background-color: #c6c6c6;
                pointer-events: initial;
                color: #fff;
                .MuiButton-label {
                  color: #fff;
                }
              }
            }
          }
          .MuiFormHelperText-marginDense {
            position: absolute;
            margin-top: 38px;
            color: #8d8d8d;
          }
          .btns.MuiButtonBase-root {
            font-size: 14px;
            border-radius: 20rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
          }
          .MuiInput-root {
            font-size: 14px;
          }
        }
      }
    }
    .right-section {
      width: 60%;
      @include tablets-max {
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
      @include phones-max {
        display: block;
      }
      .content-list {
        width: 50%;
        @include tablets-max {
          width: 50%;
        }
        @include phones-max {
          width: 100%;
        }
        display: flex;
        justify-content: space-between;
        .content-item {
          display: flex;
          cursor: pointer;
          @include laptops-xxl-max {
            width: 14rem;
          }
          @include laptops-xl-max {
            width: 10rem;
          }
          @include tablets-max {
            width: 100%;
          }
          flex-wrap: nowrap;
          font-size: 14px;
          color: #1a002f;
          padding-bottom: 0.7rem;
          padding-top: 0.7rem;
        }
      }
      .contact-support {
        width: 35%;
        @include tablets-max {
          width: 45%;
        }
        @include phones-max {
          padding-top: 2rem;
          width: 100%;
        }
        .contact-support-title {
          font-size: 14px;
          color: #1a002f;
          font-weight: bold;
          padding-bottom: 0.5rem;
        }
        .contact-support-content {
          font-size: 14px;
          .contact-support-item {
            padding-top: 1rem;
            .support-item {
              display: flex;
              justify-content: space-between;
              padding-bottom: 1rem;
            }
            &.suggestion {
              color: #e2b707;
            }
            &.complaint {
              color: #f55200;
            }
            &.tribute {
              color: #580dbf;
            }
          }
          .mdi::before {
            color: #1a002f;
          }
        }
      }
    }
  }
  .footer-bottom {
    padding: 2rem;
    .copy-right {
      font-size: 12px;
      color: #a8a8a8;
    }
    .copy-right-items {
      display: flex;
      width: 100%;
      @include phones-max {
        display: contents;
      }
      .footer-copyright {
        width: 50%;
        @include phones-max {
          width: 100%;
        }
        color: #a8a8a8;
        font-size: 12px;
        padding-bottom: 0.6rem;
        .privacy-terms {
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          color: #1a002f;
          @include phones-max {
            justify-content: space-between;
          }
          .terms-item {
            padding-right: 2rem;
            @include phones-max {
              padding-right: 0;
            }
          }
        }
      }
      .footer-social {
        width: 50%;
        @include phones-max {
          width: 100%;
        }
        display: flex;
        justify-content: flex-end;
        @include phones-max {
          justify-content: space-between;
        }
        .social-items {
          display: flex;
        }
        .download-application {
          color: #1a002f;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          .mdi {
            @include rtl {
              margin-bottom: -1px;
            }
          }
        }
        @include phones-max {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        .social-item {
          margin-left: 0.7rem;
          margin-right: 0.7rem;
          @include tablets-max {
            margin-left: 0.3rem;
            margin-right: 0.3rem;
          }
        }
        .facebook {
          background-image: url('../../../../../Assets/Images/Icons/facebook.png');
          background-repeat: round;
          width: 24px;
          height: 24px;
          &:hover {
            background-image: url('../../../../../Assets/Images/Icons/facebookhover.png');
          }
        }
        .instagram {
          background-image: url('../../../../../Assets/Images/Icons/instagram.png');
          background-repeat: round;
          width: 24px;
          height: 24px;
          &:hover {
            background-image: url('../../../../../Assets/Images/Icons/instagramhover.png');
          }
        }
        .twitter {
          background-image: url('../../../../../Assets/Images/Icons/twitter.png');
          background-repeat: round;
          width: 24px;
          height: 24px;
          &:hover {
            background-image: url('../../../../../Assets/Images/Icons/twitterhover.png');
          }
        }
      }
    }
  }
}
