.date-picker-wrapper {
  @include d-flex-column;
  width: 100%;
  .label-wrapper {
    padding: 0 0.5rem 0.25rem;
    color: $c-default-dark;
    width: 100%;
  }
  .date-picker-input-wrapper {
    .MuiInputBase-root {
      @include d-flex-v-center;
      min-width: 200px;
      width: 100%;
      min-height: 35px;
      background-color: $c-white;
      border: 1px solid $c-default-dark-opacity;
      border-radius: 2rem;
    }
  }
  .MuiInputBase-input {
    color: $c-default-dark;
  }
  .MuiInputBase-adornedStart {
    > .mdi::before {
      color: $c-default-dark;
      padding: 0 0.5rem;
    }
  }
  .MuiInputAdornment-positionEnd {
    .MuiSvgIcon-root {
      fill: $c-default-dark;
    }
  }
  &.theme-dark {
    .label-wrapper {
      color: $c-white;
    }
    .date-picker-input-wrapper {
      .MuiInputBase-root {
        background-color: $c-default-dark-opacity;
      }
    }
    .MuiInputBase-input {
      color: $c-white;
    }
    .MuiInputBase-adornedStart {
      > .mdi::before {
        color: $c-white;
      }
    }
    .MuiInputAdornment-positionEnd {
      .MuiSvgIcon-root {
        fill: $c-white;
      }
    }
    &.is-open {
      .date-picker-input-wrapper {
        .MuiInputBase-root {
          background-color: $c-default-dark;
        }
      }
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
}
.date-picker-dialog-wrapper {
  .MuiDialog-paper {
    border-radius: 1rem;
    .MuiPickersDay-day,
    .MuiButton-textPrimary,
    .MuiPickersCalendarHeader-dayLabel,
    &.MuiPaper-root {
      color: $c-default-dark;
    }
    .MuiPickersCalendarHeader-iconButton {
      .MuiSvgIcon-root {
        fill: $c-default-dark;
      }
    }
    .MuiIconButton-root {
      padding: 0.25rem;
    }
    .MuiPickersToolbarText-toolbarBtnSelected,
    .MuiPickersToolbarText-toolbarTxt {
      color: $c-white;
    }
    .MuiPickersToolbar-toolbar {
      background-color: $c-primary;
    }
    .MuiPickersDay-day.MuiPickersDay-daySelected {
      background-color: $c-primary;
      color: $c-white;
    }
  }
  &.theme-dark {
    .MuiPickersDay-day,
    .MuiButton-textPrimary,
    .MuiPickersCalendarHeader-dayLabel,
    .MuiPaper-root {
      color: $c-white;
    }
    .MuiDialog-paper,
    .MuiPickersCalendarHeader-switchHeader .MuiIconButton-root {
      background-color: $c-default-dark;
    }
    .MuiPickersCalendarHeader-iconButton {
      .MuiSvgIcon-root {
        fill: $c-white;
      }
    }
  }
}
