.phones-wrapper {
  width: 100%;
  .phone-input-wrapper {
    @include d-flex-v-center;
    .phone-input {
      box-shadow: none;
    }
  }
  .label-wrapper {
    padding: 0 0.5rem 0.25rem;
    color: $c-default-dark;
    font-size: 12px;
    text-transform: capitalize;
    display: block;
    width: 100%;
  }
  &.theme-default {
    .react-tel-input .form-control {
      background-color: #3b2447;
      color: #fff;
      width: 100%;
      border-color: #3b2447;
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
      @include d-flex-v-center;
      border-radius: 2rem !important;
      padding-right: 0.5rem !important;
      padding-left: 3rem !important;
      &:hover {
        &:not([disabled]) {
          border-color:  #3b2447;
        }
      }
      @include rtl {
        padding-right: 3rem !important;
        padding-left: 0.5rem !important;
      }

      font-size: 12px;
    }
    .react-tel-input .selected-flag .arrow {
      @include rtl {
        right: 29px;
      }
    }
    .country {
      padding: 7px 9px;
      display: flex;
      justify-content: space-between;
      color: $c-default-dark;
    }
    .react-tel-input .selected-flag {
      padding-left: 0.5rem;
      border-radius: 0.5rem 0 0 0.5rem;
      @include rtl {
        padding-left: 0;
        padding-right: 0.5rem;
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
    .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
      background-color: #977aa7;
      border-color: #3b2447;
      border-radius: 2rem 0rem 0rem 2rem;
    }
    .react-tel-input .flag-dropdown.open .selected-flag {
      background-color: #977aa7;
      border-color: #3b2447;
      border-radius: 2rem 0rem 0rem 2rem;
    }
    .flag-dropdown.open {
      z-index: 2;
      background-color: #114cf0;
      border-radius: 2rem 0rem 0rem 2rem;
    }
    .react-tel-input {
      &.phone-error {
        .form-control {
          border-color: red !important;
        }
      }
    }
  }

  .flag-dropdown {
    background-color: #3b2447;
    border: 1px solid #3b2447;
    border-radius: 2rem 0rem 0rem 2rem;
    &::before {
      content: initial !important;
    }
    .country-list {
      width: 100%;
      min-width: 270px;
      max-height: 305px;
      max-width: 17rem;
      position: fixed;
      border-radius: 12px;
    }
    .search {
      display: block;
      z-index: 1;
      background-color: #3b2447;
    }
  }
  .error-wrapper {
    width: 100%;
    margin: 0.25rem 0;
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    padding: 0 1rem;
    // padding-top: 0.25rem;
  }
  .phone-input-wrapper {
    position: relative;
    @include d-flex;
  }
  .loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
  }
}
