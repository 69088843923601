.autocomplete-wrapper {
  @include d-inline-flex-center;
  width: 100%;
  min-width: 270px;
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    padding-bottom: 0;
  }
  .autocomplete {
    @include d-flex;
    width: 100%;
    font-size: $fz-primary;
    .MuiChip-root {
      .MuiChip-deleteIcon {
        margin: 0 5px 0 -6px;
        @include rtl {
          margin: 0 -6px 0 5px;
        }
      }
    }
    .MuiAutocomplete-endAdornment {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      @include rtl {
        right: initial;
        left: 0;
      }
    }
    .dropdown-icon-wrapper,
    .dropdown-close-icon-wrapper {
      @include d-flex-center;
      color: $c-default-dark;
      font-size: $fz-22px;
      &::before {
        font-size: $fz-22px;
      }
    }
    .input-wrapper {
      margin-bottom: 0;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    @include rtl {
      padding-right: 0;
      padding-left: 56px;
    }
  }
  .MuiChip-root {
    height: 27px;
  }
  &.theme-dark {
    .autocomplete {
      .dropdown-icon-wrapper,
      .dropdown-close-icon-wrapper {
        color: $c-white;
      }
      .text-field-wrapper {
        .inputs {
          .MuiInputBase-input {
            &::placeholder {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.autocomplete-popper-wrapper {
  .MuiAutocomplete-paper {
    border-radius: 1rem;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    .MuiAutocomplete-noOptions {
      color: $c-default-dark;
    }
    .MuiAutocomplete-listbox {
      .MuiAutocomplete-option {
        // background-color: transparent;
        color: $c-default-dark;
      }
    }
  }
  &.theme-dark {
    .MuiAutocomplete-paper {
      background-color: $c-default-dark !important;
      border: 1px solid $c-default-dark !important;
      .MuiAutocomplete-noOptions {
        color: $c-white;
      }
      .MuiAutocomplete-listbox {
        .MuiAutocomplete-option {
          &[aria-selected='true'] {
            background-color: #4b0f7c !important;
          }
          color: $c-white;
        }
      }
    }
  }
  &.with-backdrop {
    background-color: raba(0, 0, 0, 0.3);
  }
}
