.FavoriteTourswrapper {
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 78%;
  margin: auto;
  .favourite-tours-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  @include tablets-max {
    width: 83%;
  }
  @include laptops-max {
    width: 90%;
  }
  @media screen and (max-width: 1275px) {
    width: 95%;
  }
  @include laptops-l-max {
    width: 90%;
  }
  @include laptops-xl-max {
    width: 77%;
  }
  .title-conteaner {
    margin-bottom: 3rem;
  }
  .main-title {
    font-size: 2.125rem;
    color: $c-default-dark;
    font-weight: bolder;
  }
  .description {
    font-size: 0.755rem;
    color: $c-default-dark;
    width: 50%;
    @include phones-s-max {
      width: 100%;
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex-containerNotour {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 416px;
  }
}
.NoTouryetView {
  .MuiButtonBase-root {
    color: #580dbf !important;
    font-size: 13px !important;
    border: 1px solid #501ab7 !important;
    border-radius: 19px !important;
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.ImageNotouryet {
  width: 59%;
  height: 100%;
  @include rtl {
    transform: scaleX(-1);
  }
}

.Contral {
  justify-content: center;
  position: absolute;

  @include phones-max {
  }
}
.text-title {
  font-size: xx-large;
  margin-bottom: 37px;
  @include phones-max {
  }
}
.Notouryetwrapper {
  display: flex;
  justify-content: center;
}
