.seaction-continer {
  padding: 30px;
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(
      90deg,
      rgba(255, 127, 24, 1) -15%,
      rgba(255, 19, 100, 1) 43%,
      rgba(43, 6, 73, 1) 300%
    );
  }
  .Seaction-Titel-contenar {
    @media screen and (min-width: 2561px) {
      margin-left: 9%;
      @include rtl {
        margin-right: 9%;
      }
    }
    @media screen and (min-width: 3633px) {
      margin-left: 16%;
      @include rtl {
        margin-right: 16%;
      }
    }
    @media screen and (min-width: 5033px) {
      @include rtl {
        margin-right: 28%;
      }
      margin-left: 28%;
    }
    margin-left: 50px;
    @include rtl {
      margin-right: 300px;
      margin-top: 50px;
    }
    @include tablets-max {
      margin-left: 30px;
      margin-top: -49px;
      @include rtl {
        margin-right: 50px;
      }
    }
    @include phones-max {
      @include rtl {
        margin-right: 11px;
        margin-top: -45px;
      }
    }
  }

  .seaction-titel {
    color: #501ab7;
    font-size: 20px;
    @include phones-max {
      font-size: 12px;
    }
  }

  .seaction-titel-main {
    color: #2e0e66;
    font-size: 26px;
    @include phones-max {
      font-size: 15px;
    }
  }
  .sub-title-1 {
    color: #676767 !important;
    font-size: 17px;
    width: 400px;
    @include phones-max {
      font-size: 12px;
      width: 245px;
    }
  }
  .seaction-titel-sub {
    color: #f55200;
    font-size: 22px;
    @include phones-max {
      font-size: 13px;
    }
  }

  .arrow {
    font-size: 25px;
    @include phones-max {
      font-size: 13px;
    }
  }

  .flex-container-ToursRecommended {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    width: 100%;
    padding-top: 2%;
    padding-bottom: 5%;
    padding-left: 3%;
    padding-right: 3%;
    @media screen and (min-width: 2561px) {
      justify-content: center;
    }
    @media screen and (min-width: 3633px) {
      justify-content: center;
    }
    @media screen and (min-width: 5033px) {
      justify-content: center;
    }
    @include rtl {
      padding-left: 2%;
      padding-right: 2%;
    }
    .card-point-container {
      margin-right: 20px;
      border-radius: 10px;
      background-color: rgb(255, 255, 255);
      border: 1px solid #7070701f;
      box-shadow: 1px 4px 11px 0px #88888873;
      width: 262px;
      height: fit-content;
      padding: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      @include rtl {
        margin-left: 20px;
      }
    }
    .card-point-container:hover {
      background-color: rgb(255, 255, 255);
      border: 1px solid #70707045;
      box-shadow: 8px -12px 2px 2px #ff136440;
      @include rtl {
        box-shadow: -8px -12px 2px 2px #ff136440;
      }
    }

    .MuiTimeline-root {
      display: flex;
      padding: 1px 1px;
      flex-grow: 1;
      flex-direction: column;
    }

    .MuiTimelineItem-missingOppositeContent:before {
      flex: 1;
      content: '';
      padding: 0px 1px;
    }

    .card-point {
      margin-top: 20px;
    }
    .main-title {
      font-size: 17px;
      font-weight: 600;
      color: #2b0649;
    }
    .price-title {
      font-size: 18px;
      color: #ff7f18;
    }
    .from {
      font-size: 12px;
      color: #2b0649;
      margin-right: 17px;
      @include rtl {
        margin-left: 17px;
      }
    }
    .Image-container {
      width: 210px;
    }
    .Image {
      width: 100%;
      height: 59px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 100%;
    }
    .Rating {
      color: #f5a81d;
      font-size: 14px;
      display: contents;
    }
    .details {
      display: inline-flex;
      .detaile-name {
        color: #2b0649;
        font-size: 14px;
        padding-right: 17px;
        @include rtl {
          padding-left: 17px;
        }
      }
    }
    .contact-field {
      @include d-flex-v-center-h-between;
    }

    .favoritebuuton {
      color: #2b0649;
      font-size: 24px;
      margin-left: 70px;
      @include rtl {
        margin-right: 70px;
        margin-left: 0px;
      }
    }
    .favoritebuutonON {
      color: #c72525;
      font-size: 24px;
      margin-left: 70px;
      @include rtl {
        margin-right: 70px;
        margin-left: 0px;
      }
    }
    .MuiTimelineDot-defaultGrey {
      color: #fafafa;
      border-color: transparent;
      background-color: #333333;
    }

    .MuiTimelineConnector-root {
      width: 2px;
      flex-grow: 1;
      background-color: #00000040;
    }
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: #f5590a;
  }
  .MuiTab-textColorPrimary {
    color: #f66a24a1;
  }
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: #f55200;
  }
  .MuiTabs-root {
    float: right;
    margin-right: 10%;
    @include rtl {
      float: left;
      margin-left: 10%;
    }
  }
  .All {
    background-color: whitesmoke;
    float: right;
    @include rtl {
      float: left;
    }
  }
  .Arrow-Contenar {
    margin-top: 20px;
    margin-right: 11%;
    @include rtl {
      margin-right: 0%;
      margin-left: 11%;
    }
  }
  .bbt-Contenar {
    float: right;
    @include rtl {
      float: left;
    }
  }

  .KeyboardArrow {
    color: #ff7f18;
  }
}
