.tours-discover-view-wrapper {
  min-height: 100vh;
  @include d-flex-column;
  width: 100%;
  color: $c-white;
  position: relative;
  overflow: hidden;
  .loadable-skeleton {
    border-radius: 1rem;
  }
  .input-wrapper.theme-dark .text-field-wrapper .inputs .MuiInputBase-input {
    font-weight: bold;
  }
  .canvas-wrapper {
    @include d-flex;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    .canvas-items-wrapper {
      direction: ltr;
      @include d-flex;
      position: absolute;
      left: 0;
      transition: transform 7.0s linear;
      width: 102vw;
      min-height: 1500px;
      // min-height: 1577px;
      min-width: 3284px;
      background: $bg-gradient-default;
      height: 102vh;
      .canvas-item-wrapper {
        position: absolute;
        left: 0;
        .canvas-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          border-radius: 1rem;
        }
        .hover-item-wrapper {
          position: absolute;
          @include d-flex-column;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 1rem;
          opacity: 0;
          transition: opacity 1s linear;
          background-color: rgba($c-black, 0.3);
          &:hover {
            opacity: 1;
          }
          .hover-item-content {
            @include d-inline-flex-column;
            padding: 0 0.5rem;
            margin-bottom: 0.5rem;
            width: 100%;
          }
        }
      }
    }

    .canvas-filter-wrapper {
      @include d-inline-flex-column-center;
      position: absolute;
      bottom: 12%;
      left: 50%;
      min-width: 900px;
      @include tablets-max {
        min-width: initial;
        bottom: 5%;
        width: 98%;
      }
      transform: translateX(-50%);
      @include rtl {
        transform: translateX(50%);
        right: 50%;
        left: initial;
      }
    }

    .canvas-filter-items-wrapper {
      @include d-flex-h-center;
      background-color: #2b2430;
      border-radius: 1rem 1rem 2rem 1rem;
      padding: 0.5rem 0.5rem 0;
      margin-bottom: 1rem;
      .input-wrapper.theme-dark .text-field-wrapper .inputs .MuiInputBase-root,
      .display-wrapper {
        background-color: #48424e;
        border-color: #48424e;
      }
      @include laptops-max {
        flex-wrap: wrap;
        .label-wrapper {
          display: none;
        }
        .display-wrapper {
          background-color: transparent;
          border-color: transparent;
        }
        .description-text-wrapper .description-text {
          display: none;
        }
        .canvas-filter-item {
          width: 33.3333%;
          &:nth-child(2) {
            width: 35.3333%;
          }
          &:nth-child(3) {
            width: 31.3333%;
          }
          @include tablets-max {
            &:not(:first-child) {
              width: 100px;
            }
          }

          .theme-dark,
          .display-wrapper {
            min-width: 100%;
            .dropdown-content-wrapper {
              padding: 0;
            }
          }
          .display-wrapper {
            @include tablets-max {
              max-width: 100px;
              min-width: initial;
              .dropdown-content-wrapper {
                display: none;
              }
            }
          }
          &:nth-child(1) {
            width: 100%;
          }
        }
      }

      @include rtl {
        border-radius: 1rem 1rem 1rem 2rem;
      }
      .canvas-filter-item {
        @include d-inline-flex;
        padding: 0 0.5rem;
        margin-bottom: 1rem;
        position: relative;

        &:after {
          content: ' ';
          height: 100%;
          width: 1px;
          min-width: 1px;
          background-color: $c-white;
          position: absolute;
          right: 0;
          top: 0.25rem;
          @include rtl {
            right: initial;
            left: 0;
          }
        }
        &:last-child {
          &::after {
            content: none;
          }
        }
        @include laptops-max {
          &:first-child {
            &:after {
              content: none;
            }
          }
          &:after {
            top: 0;
          }
        }
      }
    }
  }
}
@keyframes canvasTitleAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes canvasTitleTextAnimation {
  0% {
    transform: translateY(90%);
  }
  100% {
    // transform: scaleY(1);
    transform: translateY(0);
  }
}
