.header-wrapper {
  .language-select {
    .MuiButton-root {
      text-transform: capitalize;
    }
    .MuiAccordion-root {
      padding: 0;
      margin-top: -0.1rem;
      margin-left: -1rem;
      @include rtl {
        margin-left: 0;
        margin-right: -1rem;
      }
      margin-left: -1rem;
      background: transparent !important;
      color: white;
      width: 100%;
    }
    .MuiAccordionSummary-root {
      width: 110%;
    }
    .MuiAccordionDetails-root {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .MuiTypography-root {
        margin-left: 1rem;
        margin-right: 1rem;
        color: white;
        font-size: 16px;
      }
    }
    .MuiSvgIcon-root {
      color: white;
    }
    .MuiPaper-elevation1 {
      box-shadow: 0 0 0 0;
    }
  }
  .header-appbar {
    padding-top: 0rem;
    @include laptops-xxl-max {
      padding-left: 25rem;
      padding-right: 25rem;
    }
    @include laptops-xl-max {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    @include laptops-l-max {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @include tablets-max {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @include phones-max {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .mobile-logo {
    @include phones-max {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      .mdi::before {
        font-size: 24px;
      }
    }
  }
  .nav-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    .nav-item {
      .MuiButton-root {
        min-width: 7.5rem;
        height: 1.8rem;
        border-radius: 24px;
        text-transform: capitalize;
        color: white;
      }
    }
  }
  .nav-cart {
    .MuiIconButton-root {
      color: white;
      padding: 0.4rem;
      .mdi {
        font-size: 20px;
      }
    }
  }
  .cart-login-section {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    @include laptops-xxl-max {
      width: 25%;
    }
    @include laptops-xl-max {
      width: 35%;
    }
    @include laptops-l-max {
      width: 45%;
    }
    @include tablets-max {
      width: 50%;
    }
  }
  .nav-button {
    @include d-inline-flex;
    .MuiIconButton-label {
      font-size: 18px;
    }
    .MuiIconButton-root {
      color: white;
      padding: 0.4rem;
      .mdi {
        font-size: 20px;
      }
    }
  }
  .custom-button {
    .MuiButton-root {
      min-width: 7rem;
      height: 1.8rem;
      background: #570dbf5b;
      border-radius: 24px;
      text-transform: capitalize;
      color: white;
    }
  }
  .tours-buttons {
    display: flex;
    padding-left: 3rem;
    padding-right: 3rem;
    .MuiButton-root {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @include laptops-xxl-max {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @include laptops-xl-max {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include laptops-l-max {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @include tablets-max {
      .MuiButton-root {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .pst-logo {
    padding-top: 0.4rem;
    cursor: pointer;
  }
  .MuiToolbar-root {
    width: 100%;
    min-height: 50px;
  }
  .MuiAppBar-colorPrimary {
    background: rgba(36, 36, 36, 0.767);
    opacity: 1;
    backdrop-filter: saturate(180%) blur(20px);
  }
  @include laptops-xxl-max {
    .nav-cart {
      display: none;
    }
  }
  @include phones-max {
    .nav-items {
      display: none;
    }
    .nav-cart {
      display: contents;
    }
  }
  .cart-button,
  .nav-item,
  .language-item,
  .custom-button {
    @include d-inline-flex-center;
  }
}
.header-menu-wrapper {
  .MuiList-padding {
    padding: 1rem;
  }
  .MuiDivider-root {
    background-color: rgba(114, 114, 114, 0.746);
  }
  .MuiDrawer-paperAnchorLeft {
    width: 70%;
  }
  .MuiDrawer-paper {
    background: rgba(36, 36, 36, 0.767) !important;
    backdrop-filter: saturate(180%) blur(20px) !important;
    top: 56px !important;
    height: 55% !important;
    color: white !important;
    opacity: 1;
    border-radius: 0px 0px 20px 0px;
  }
  .MuiBackdrop-root {
    background-color: transparent !important;
  }

  @include rtl {
    .MuiDrawer-paper {
      border-radius: 0px 0px 0px 20px;
      right: 0;
      left: auto;
    }
    .MuiListItem-root {
      text-align: right;
    }
  }
}
