.BasketTourswrapper {
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 70%;
  margin: auto;
  .opation-wraper {
    width: 100%;
    padding-bottom: 1.5rem;
    .opation {
      margin: 0 10px 0 10px;
      width: 26rem;
      display: flex;
      justify-content: center;
      color: #580dbf;
      .opation-bbt {
        border-bottom: 1px solid #707070;
        padding: 0.7rem;
        width: 100%;
        &.isActive {
          border-bottom: 3px solid $c-primary !important;
          color: $c-primary;
        }
      }
    }
  }
  .basket-tours-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .Total-summary {
    width: 25%;
    .summary {
      width: 100%;
      height: 40px;
      border-radius: 10px;
      background-color: #ff5e0d;
      color: white;
      justify-content: center;
      display: flex;
      .titel {
        display: flex;
        align-items: center;
      }
    }
    .tour {
      width: 100%;
      height: 96px;
      border-radius: 10px;
      box-shadow: 0px 0px 13px #0000001a;
      margin: 15px 0;
      display: flex;
      font-size: 14px;
      .tour-name {
        font-size: 14px;
        width: 60%;
        margin: 10px;
        .tour-order {
          font-size: 17px;
          color: #333333;
          width: 100%;
          margin: 10px;
        }
        .order-name {
          font-size: 14px;
          color: #333333;
          width: 100%;
        }
      }
      .details {
        margin: 10px;
        width: 40%;
        text-align: right;
        .Show-details {
          font-size: 12px;
          color: #ff5e0d;
          margin: 12px;
        }
        .price {
          font-size: 14px;
          color: #2b2b2b;
          margin: 12px;
        }
      }
    }
    .total {
      width: 100%;
      height: 148px;
      border-radius: 10px;
      box-shadow: 0px 0px 13px #0000001a;
      margin: 15px 0;
      display: flex;

      .total-title {
        width: 50%;
        margin: 12px;
        display: grid;
        color: #333333;
        .price {
          color: #ff5e0d;
        }
      }
      .total-price{
        width: 50%;
        margin: 16px;
        display: grid;
        text-align: right;
        color: #333333;
        .price {
          color: #ff5e0d;
        }
      }
    }
  }
  @include tablets-max {
    width: 83%;
  }
  @include laptops-max {
    width: 90%;
  }
  @media screen and (max-width: 1275px) {
    width: 95%;
  }
  @include laptops-l-max {
    width: 90%;
  }
  @include laptops-xl-max {
    width: 70%;
  }
  .title-conteaner {
    margin-bottom: 3rem;
  }
  .main-title {
    font-size: 2.125rem;
    color: $c-default-dark;
    font-weight: bolder;
  }
  .description {
    font-size: 0.755rem;
    color: $c-default-dark;
    width: 50%;
    @include phones-s-max {
      width: 100%;
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex-containerNotour {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 416px;
  }
}
.NothingOnBasketView {
  .text-title {
    font-size: xx-large;
  }
}
.ImageNotouryet {
  width: 59%;
  height: 100%;
  @include rtl {
    transform: scaleX(-1);
  }
}

.Contral {
  justify-content: center;
  position: absolute;

  @include phones-max {
  }
}
.text-title {
  margin-bottom: 37px;
  font-size: 12px;
  @include phones-max {
  }
}
.Notouryetwrapper {
  display: flex;
  justify-content: center;
}
