.loadable-image-componant {
  .show {
    display: block;
  }
  .hidden {
    display: none;
  }
  &.image-loader {
    position: relative;
    background-size: 100px !important;
    background-repeat: no-repeat !important;
    background-position: center;
    filter: initial !important;
    height: 100%;
    min-height: 100px;
  }
}
