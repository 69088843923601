.download-wrapper {
  width: 100%;
  padding-top: 5rem;
  display: flex;
  align-items: center;
  .mdi-microsoft-windows {
    color: darkgray;
  }
  .windows {
    .MuiButton-root {
      border: 1px solid darkgray !important;
    }
  }
  .download-content {
    @include laptops-xxl-max {
      padding-left: 35rem;
      padding-right: 35rem;
    }
    @include laptops-xl-max {
      padding-left: 15rem;
      padding-right: 15rem;
    }
    @include laptops-l-max {
      padding-left: 10rem;
      padding-right: 10rem;
    }
    @include laptops-max {
      padding-left: 5rem;
      padding-right: 5rem;
    }
    @include tablets-max {
      padding-left: 0;
      padding-right: 0;
    }
    @include rtl {
      flex-direction: row-reverse;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    .download-mobile {
      display: flex;
      justify-content: flex-end;
      .download-video {
        @include laptops-xxl-max {
          width: 350px;
        }
        @include tablets-max {
          width: 300px;
        }
        @include phones-max {
          width: 200px;
        }
        @include phones-s-max {
          width: 140px;
        }
      }
    }
    .download-text {
      display: grid;
      justify-content: flex-start;
      .download-app {
        font-size: 42px;
        color: #2e0e66;
        @include laptops-max {
          font-size: 38px;
        }
        @include tablets-max {
          font-size: 28px;
        }
        @include phones-max {
          text-align: center;
          width: 11rem;
          font-size: 24px;
        }
        @include phones-s-max {
          width: 10rem;
          font-size: 22px;
        }
      }
      .download-description {
        font-size: 18px;
        padding-top: 1rem;
        color: #2e0e66;
      }
      .download-buttons {
        padding-top: 2rem;
        display: flex;
        .download-store {
          text-align: left;
          @include rtl {
            text-align: right;
          }
        }
        .MuiButton-root {
          text-transform: capitalize;
          display: flex;
          border: 1px solid #2e0e66;
          margin-right: 2rem;
          border-radius: 20rem;
          padding: 0;
          width: 13rem;
          font-size: 11px;
          font-weight: bold;
          color: #2e0e66;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
          @include tablets-max {
            width: 10rem;
            font-size: 10px;
          }
          @include phones-max {
            width: 9.5rem;
            font-size: 9px;
          }
          @include rtl {
            margin-left: 2rem;
            margin-right: 0;
            flex-direction: row-reverse;
          }
          .download-apple {
            font-size: 14px;
            @include tablets-max {
              font-size: 12px;
            }
          }
          .download-windows {
            text-align: left;
            @include rtl {
              text-align: right;
            }
            color: darkgray;
            font-size: 11px;
            @include tablets-max {
              font-size: 11px;
            }
          }
          .download-google {
            font-size: 14px;
            @include tablets-max {
              font-size: 12px;
            }
          }
          .mdi::before {
            font-size: 36px;
            padding-right: 0.5rem;
            @include rtl {
              padding-right: 0;
              padding-left: 1rem;
            }
            @include tablets-max {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
