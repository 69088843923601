.review-view-wrapper {
  @include d-flex-column-center;
  padding: 0.5rem 0.5rem 0;
  margin-bottom: 1rem;
  color: $c-black-light;
  .review-title-wrapper {
    @include d-flex-column-center;
    text-align: center;
    margin-bottom: 1rem;
    max-width: 25%;
    color: $c-default;
    padding: 0 1rem;
    font-size: $fz-30px;
    @include phones-max {
      font-size: $fz-20px;
      width: 100%;
    }

    :nth-child(2) {
      color: #676767;
      font-size: $fz-primary;
      @include phones-max {
        display: none;
      }
    }
  }
  .cards-wrapper {
    @include d-flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    width: 100%;
    .card-items-wrapper {
      @include d-flex;
      width: 100%;
      .card-item-wrapper {
        @include d-inline-flex-center;
        width: 25%;
        padding: 0.5rem;
        min-width: 300px;
        &:first-child {
          margin-left: auto;
          @include rtl {
            margin-left: initial;
            margin-right: auto;
          }
        }
        &:last-child {
          margin-right: auto;
          @include rtl {
            margin-left: auto;
            margin-right: initial;
          }
        }
        .card-item-content-wrapper {
          @include d-flex;
          flex-wrap: wrap;
          width: 100%;
          border-radius: 1rem;
          border: 1px solid #e8e8e8;
          .card-item-header {
            @include d-flex-v-center;
            padding: 1rem 0.5rem 0.5rem;
            .card-item-image {
              @include d-inline-flex-center;
              background-position: center;
              background-repeat: no-repeat;
              border-radius: 100%;
              margin-bottom: 0.5rem;
              margin: 0 0.5rem;
              border: 1px solid #e8e8e8;
              min-width: 50px;
              width: 50px;
              height: 50px;
            }
          }
          .card-item-body {
            padding: 1rem;
            @include d-flex;
            flex-wrap: wrap;
            height: 100%;
          }
          .card-item-footer {
            @include d-flex-v-center;
            padding: 0.5rem;
            color: $c-primary;
          }
        }
      }
    }
  }
}
