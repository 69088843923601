.AllTourswrapper {
  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 90%;
  margin: auto;
  text-align: right;
  .map-filter {
    background-image: url('../../../../../Assets/Images/images/map.png');
    width: 400px;
    height: 150px;
    border-radius: 20px;
    background-size: cover;
    position: relative;
    .view-map {
      color: #ff5e0d;
      width: 250px;
      height: 100px;
      background-color: white;
      display: grid;
      font-size: 12px;
      margin: auto;
      margin-top: 25px;
      border-radius: 25px;
      .Explore {
        color: #002d59;

        width: 90%;
        margin: auto;
        text-align: center;
      }
    }
  }
  .block {
    display: block;
    .alltours-filter {
      margin-top: 20px;
      display: flex;
      .all-tours-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 70%;
        .NoTouryetView {
          width: 100%;
          .flex-containerNotour {
            text-align: center;
            display: block;
            .Notouryetwrapper {
              .ImageNotouryet {
                width: 50%;
                height: 30rem;
              }
            }
            .text-title {
              font-size: xx-large;
            }
          }
        }
      }
    }
    .pagination {
      width: 74.5%;
      .btns-next-back {
        margin: 20px;
        display: inline-flex;
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        border-radius: 1rem;
        padding: 0.25rem 0.5rem;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        white-space: nowrap;
        min-height: 30px;
        background-image: linear-gradient(105deg, #ff7f18 0%, #ff1364 46%, #2b0649 100%);
      }
    }
  }

  @include tablets-max {
    width: 83%;
  }
  @include laptops-max {
    width: 90%;
  }
  @media screen and (max-width: 1275px) {
    width: 95%;
  }
  @include laptops-l-max {
    width: 90%;
  }
  @include laptops-xl-max {
    width: 77%;
  }
  .title-conteaner {
    margin-bottom: 3rem;
  }
  .main-title {
    font-size: 2.125rem;
    // font-weight: bolder;
  }
  .description {
    font-size: 0.755rem;
    width: 50%;
    @include phones-s-max {
      width: 100%;
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex-containerNotour {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 416px;
  }

  .AllTours-filter-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 0 1 auto;
    // flex-direction: column;
    align-items: center;
    // justify-content: center;
    min-width: 900px;
    @include tablets-max {
      min-width: initial;
      bottom: 5%;
      width: 98%;
    }

    @include rtl {
      right: 50%;
      left: initial;
    }
  }
  .all-sections {
    .section-2 {
      display: flex;
      width: 100%;
      text-align: left;
      margin: 10px;
      .px-2 {
        width: 22%;
      }
      .pagination {
        width: 78%;
        display: flex;
        .mui-pagination {
          width: 76%;
          display: flex;
          margin-top: 5px;
          justify-content: flex-end;
          @include rtl{
            text-align: end;

            .MuiPagination-root {
            .MuiPagination-ul {
            .MuiPaginationItem-sizeLarge{
              .MuiPaginationItem-icon{
                transform: scaleX(-1);
              }
            }
              
            }
          }
          }
          
        }
        .MuiPagination-root {
          .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff5e0d;
          }
        }
      }
      .select-wrapper.MuiFormControl-root {
        width: 277px;
      }
    }

    .section-1 {
      .canvas-filter-items-wrapper {
        display: inline-flex;
        // @include d-flex-h-center;
        text-align: left;
        // background-color: #2b2430;
        border-radius: 1rem 1rem 2rem 1rem;
        padding: 0.5rem 0.5rem 0;
        margin-bottom: 1rem;
        @include laptops-max {
          flex-wrap: wrap;
          .label-wrapper {
            display: none;
          }
          .display-wrapper {
            background-color: transparent;
            border-color: transparent;
          }
          .description-text-wrapper .description-text {
            display: none;
          }

          .canvas-filter-item {
            .Dropdown {
              .px-1 {
                color: #333333;
                font: 14px;
              }
            }
            width: 33.3333%;
            &:nth-child(2) {
              width: 35.3333%;
            }
            &:nth-child(3) {
              width: 31.3333%;
            }
            @include tablets-max {
              &:not(:first-child) {
                width: 100px;
              }
            }

            .theme-dark,
            .display-wrapper {
              min-width: 100%;
              .dropdown-content-wrapper {
                padding: 0;
              }
            }
            .display-wrapper {
              @include tablets-max {
                max-width: 100px;
                min-width: initial;
                .dropdown-content-wrapper {
                  display: none;
                }
              }
            }
            &:nth-child(1) {
              width: 100%;
            }
          }
        }

        @include rtl {
          border-radius: 1rem 1rem 1rem 2rem;
        }
        .canvas-filter-item {
          .AutoCompleteCostum {
            .d-flex-center {
              margin-top: 0px;
            }
          }
          @include d-inline-flex;
          padding: 0 0.5rem;
          margin-bottom: 1rem;
          position: relative;
          .label-to-go {
            margin-top: 0;
          }
          &:after {
            content: ' ';
            height: 100%;
            width: 1px;
            min-width: 1px;
            background-color: $c-white;
            position: absolute;
            right: 0;
            top: 0.25rem;
            @include rtl {
              right: initial;
              left: 0;
            }
          }
          &:last-child {
            &::after {
              content: none;
            }
          }
          @include laptops-max {
            &:first-child {
              &:after {
                content: none;
              }
            }
            &:after {
              top: 0;
            }
          }
        }
        .canvas-filter-btns-wrapper {
          margin: auto;
          height: 20px;
        }
      }
    }
  }
}
