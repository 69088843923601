.checkbox-groups-wrapper {
  &.MuiFormControl-root {
    padding: 0;
  }
  .checkbox-wrapper {
    .MuiSvgIcon-root {
      path {
        fill: $c-primary;
      }
    }
    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      .mdi {
        color: $c-white;
        background-color: $c-primary;
      }
    }
    .mdi {
      height: 18px;
      width: 18px;
      border-radius: 0.3rem;
      line-height: 13px;
      &::before {
        font-size: 17px;
      }
    }
    .i-unchecked {
      height: 18px;
      width: 18px;
      border: 2px solid $c-primary;
    }
    .PrivateSwitchBase-root-5 {
      padding: 0.5rem;
    }

    &.MuiIconButton-root {
      border-radius: 0.3rem;
    }

    &.theme-secondary {
      .MuiSvgIcon-root {
        path {
          fill: $c-primary;
        }
      }

      &.Mui-checked,
      &.MuiCheckbox-indeterminate {
        .mdi {
          background-color: $c-primary;
        }
      }
    }
  }
  &.theme-dark {
    .MuiFormControlLabel-label {
      color: $c-white;
    }
  }
}
