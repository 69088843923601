.dropdown-component {
  @include d-flex-column;
  width: 100%;
  .label-wrapper {
    padding: 0 0.5rem 0.25rem;
    color: $c-default-dark;
    width: 100%;
  }
  .display-wrapper {
    @include d-flex-v-center-h-between;
    flex: 0 0 auto;
    width: 100%;
    padding: 0.25rem 0.5rem;
    min-width: 287px;
    border-radius: 2rem;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    .description-text {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .arrow-icon {
      font-size: 22px;
      font-weight: normal;
      &::before {
        font-size: 22px;
        font-weight: normal;
      }
    }
    .arrow-icon,
    .description-text-wrapper,
    .description-icon,
    .description-text,
    .dropdown-content-wrapper,
    .label-wrapper {
      color: $c-default-dark;
    }
    .dropdown-content-wrapper {
      @include d-inline-flex-center;
      white-space: nowrap;
      font-weight: $fw-bold;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .description-text-wrapper {
      @include d-inline-flex-center;
    }

    // border: 1px solid #e6e6e6;
    &.has-error {
      border-color: $c-error;
      .mdi,
      .description-text {
        color: $c-error !important;
      }
      .start-date-error,
      .end-date-error {
        color: $c-error;
      }
    }
  }
  .error-wrapper {
    color: $c-error;
  }
  &.theme-dark {
    .label-wrapper {
      color: $c-white;
    }
    .display-wrapper {
      background-color: $c-default-dark-opacity;
      border: 1px solid $c-default-dark-opacity;
      .arrow-icon,
      .description-text-wrapper,
      .description-icon,
      .description-text,
      .dropdown-content-wrapper,
      .label-wrapper {
        color: $c-white;
      }
    }
  }
  &.is-open {
    .display-wrapper {
      background-color: $c-default-dark;
      .arrow-icon,
      .description-text-wrapper,
      .description-icon,
      .description-text,
      .dropdown-content-wrapper,
      .label-wrapper {
        color: $c-white;
      }
    }
  }
  &.has-error {
    .display-wrapper {
      border-color: $c-error;
      .arrow-icon,
      .description-text-wrapper,
      .description-icon,
      .description-text,
      .dropdown-content-wrapper,
      .label-wrapper {
        color: $c-error;
      }
    }
  }
}
