.car-dialog-wrapper {
  background-color: $c-default-dark;
  padding: 1rem 0.5rem 0.5rem;
  max-width: 300px;
  .header-wrapper {
    @include d-flex-v-center;
    color: $c-white;
  }
  .form-control-label {
    margin: 0;
  }
  .body-wrapper {
    @include d-flex-column;
    .item-wrapper {
      @include d-flex-column;
      position: relative;
      width: 100%;
      padding: 1rem 0;
      margin-bottom: 1rem;
      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        min-height: 1px;
        background-color: #4d4d4d;
      }
      .item-body {
        @include d-flex-v-center-h-between;
        width: 100%;
        margin-bottom: 0.5rem;
      }
      .item-footer {
        @include d-flex-column;
        width: 100%;
        max-height: 78px;
        overflow-y: auto;
      }
      .btns-icons {
        margin: 0 0.5rem 0.5rem;
      }
      .details-wrapper {
        @include d-flex-column-center;
        color: $c-white;
      }
      .description-text {
        font-size: $fz-12px;
        color: #989898;
      }
    }
    .items-wrapper {
      .item-checkboxes-wrapper {
        max-height: 100px;
        overflow-y: auto;
      }
    }
  }
}
