$c-white: #fff;
$c-black: #000000;
$c-black-light: #121212;
$c-default: #002d59;
$c-default-dark: #2b0649;
$c-default-dark-opacity: #3b2447;
$c-default-light: #501ab7;
$c-primary: #f55200;
$c-primary-light: #c16b25;
$c-secondary: #c62059;
$c-yellow: #f5a81d;
$c-danger: #cc2222;
$c-warning: #ff6f59;
$c-error: #f44336;
$c-gray-light:#d3d3d3;
$bg-gradient-default: linear-gradient(119deg, $c-default-light 0%, $c-default 30%, $c-black 100%);
$bg-gradient-default-reversed: linear-gradient(
  -119deg,
  $c-default-light 0%,
  $c-default 30%,
  $c-black 100%
);
$bg-gradient-primary: linear-gradient(105deg, #ff7f18 0%, #ff1364 46%, $c-default-dark 100%);
$bg-gradient-primary-reversed: linear-gradient(
  -105deg,
  #ff7f18 0%,
  #ff1364 46%,
  $c-default-dark 100%
);
$bg-gradient-secondary: linear-gradient(
  279deg,
  $c-primary-light 0%,
  $c-secondary 37%,
  $c-default-dark 100%
);
$bg-gradient-secondary-reversed: linear-gradient(
  -279deg,
  $c-primary-light 0%,
  $c-secondary 37%,
  $c-default-dark 100%
);
