@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('../../../../Fonts/Poppins-Regular.ttf');
}
$fz-primary: 1rem;
$fz-12px: 12px;
$fz-14px: 14px;
$fz-16px: 16px;
$fz-19px: 19px;
$fz-20px: 20px;
$fz-22px: 22px;
$fz-24px: 24px;
$fz-30px: 30px;
$fz-40px: 40px;
$fz-75px: 75px;
$ff-primary: Poppins, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace;
$fw-primary: 400;
$fw-medium: 500;
$fw-bold: bold;
