.container{
	.location-icon{
		
		text-align: center;
		.num{
			color: white;
			font-size: 20px;
			position: absolute;
			margin-left: 12px;
			@include rtl{
				margin-right: 12px;
			}
		}
		img{
			position: absolute;
			width: 30px;
		}
	}
	position: relative;
	&:hover{
		 .container-inner {
			transform: rotateY(180deg);
		
		  }
	}
	.container-inner{
		position: relative;
		width: 100%;
		transition: transform 0.6s;
		transform-style: preserve-3d;
	

.DetailsCardUiComponentwrapper {
	margin: 0 20px 0 20px;
	width: 330px;
	height: 430px;
	max-width: 400px;
	max-height: 430px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	$Orange: #f55200;
	$secondary: #2b0649;
	$primary: #002d59;
	$primary-light: #580dbf;
	$primary: #002d59;
	@include phones-max {
		margin-right: 0px;
		margin-left: 0px;
	}
	.Imagewrapper {
		text-align: center;
		width: 100%;
		position: relative;
	}
	.price-contenar
	{
		margin-top: 10px;
	}
	.trip-contenar
	{
		margin-top: 10px;
		span{
			margin: 0 0 0 23px;
			@include rtl{
				margin: 0 15px 0 0;
			}
		}
	}
	.Imagemain {
		width: 90%;
		height: 160px;
		border-radius: 10px;
	}
	.Imagesecondary {
		margin-top: 10px;
		border-radius: 10px;
		width: 363.27px;
		opacity: 0.5;
		height: 155px;
	}
	.place-name {
		display: flex;
		position: absolute;
		background: #00000081 0% 0% no-repeat padding-box;
		border-radius: 30px;
		margin-left: 40px;
		margin-top: 15px;
		width: 130px;
		z-index: 10;
		height: auto;
		color: white;
		font-size: 12px;
		padding-left: 13px;
		padding-right: 11px;
		padding-top: 2.5px;
		@include rtl {
			margin-right: 40px;
		}
	}
	.hearticon {
		position: absolute;
		color: white;
		padding-left: 292px;
		z-index: 10;
		padding-top: 17px;
		@include rtl {
			padding-left: 0px;
			padding-right: 292px;
		}
	}
	
	.name-of-trip {
		font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.56;
  letter-spacing: normal;
		margin: 15.1px 19.1px 0 40.8px;
		font-size: 16px;
		color: $primary;
		font-weight: bolder;
	}
	.from-price {
		font-size: 1.5rem;
		color: $Orange;
		font-weight: bolder;
		padding-top: 6.4px;
		padding-right: 10.8px;
	}
	.btt-See-details {
		width: 90px;
		height: 25px;
		background-color: transparent;
		font-size: 0.75rem;
		font-weight: 600;
		text-transform: inherit;
		color: $primary-light;
		border: 1px solid #501ab7;
		border-radius: 30px;
		appearance: button !important;
		cursor: pointer;
	}
	.About {
		font-size: 0.85rem;
		color: $secondary;
		font-weight: bolder;
		padding-top: 14px;
	}
	.Content {
		padding-top: 6.5px;
		font-size: 0.75rem;
		color: $secondary;
		opacity: 0.72;
	}
	.details {
		
	.time-rate{
display: inline-flex;
margin: 0 0 0 15px;
@include rtl{
	margin: 0 5px 0 15px;
}
	}
		padding-top: 6.5px;
		font-size: 1rem;
		font-weight: bolder;
		color: $secondary;
		.details-Container {
			padding: 0.5rem;
		}
		.location-Container{
			margin: 0 0 0 21px;
			max-height: 20px;
			width: 90%;
			@include rtl{
				margin: 0 12px 0 21px;
			}
    overflow: hidden;
		}
		
		.description-Container{
			margin: 10px 0 10px 15px;
			.description-title{
				margin: 10px 15px 15px 15px;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.58;
				letter-spacing: normal;
			}
			.description-content{
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
	max-height:37px;
				@supports (display: -webkit-box) {
				  display: -webkit-box;
				  -webkit-box-orient: vertical;
				  -webkit-line-clamp: 2;
				}
				margin: 10px 15px 15px 15px;
				line-height: 18px;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: normal;
			}
			

		}
	
	}
	.ShoppIcon {
		padding-left: 75px;
		position: absolute;
		@include rtl {
			padding-right: 50px;
			padding-left: 0px;
		}
	}
	.MuiIconButton-colorPrimary {
		color: #ffffff;
		background-color: $Orange;
	}
	.MuiIconButton-colorPrimary:hover {
		background-color: #f552008f;
	}
}

.DetailsCardUiComponentwrapper-flip{
	margin: 0 20px 0 20px;
	width: 330px;
	height: 430px;
	max-width: 400px;
	max-height: 430px;
	transform: rotateY(180deg);
	background-color: white;
	position: absolute;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
		border-radius: 5px;
	  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		$secondary: #2b0649;
		@include phones-max {
			margin-right: 0px;
			margin-left: 0px;
		}
	.description-Container{
		height: 50%;
	
		margin: 10px 0 10px 15px;
		.description-title{
				font-weight: bold;
			color: $secondary;
			margin: 10px 15px 15px 15px;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.58;
			letter-spacing: normal;
		}
		.description-content{
			height: 70%;
			width: 90%;
			overflow: scroll;
		overflow-x: hidden;
			color: $secondary;
			margin: 10px 15px 15px 15px;
			line-height: 18px;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
		}
	}
	.note-Container{
		height: 50%;
		margin: 10px 0 10px 15px;
		.note-title{
			position: relative;
			font-weight: bold;
			color: $secondary;
			margin: 10px 15px 15px 15px;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.58;
			letter-spacing: normal;
		}
		.note-content{
			height: 70%;
			width: 90%;
			overflow: scroll;
		overflow-x: hidden;
			color: $secondary;
			margin: 10px 15px 15px 15px;
			line-height: 18px;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
		}
	}
	
}
}
}