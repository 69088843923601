.custom-tour-view-wrapper {
  @include d-flex-column-center;
  width: 100%;
  position: relative;
  max-width: 100%;
  min-height: 780px;
  background-color: $c-default;
  background-repeat: no-repeat;
  background-image: $bg-gradient-secondary;
  background-size: 300% 100%;
  transition: background-position 0.5s linear;
  @include rtl {
    background-image: $bg-gradient-secondary-reversed;
  }
  .tracker-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    color: $c-white;
    &::-webkit-scrollbar {
      // height: 0;
      display: none;
    }
    scroll-behavior: smooth;
    z-index: 1;
    //   &::-webkit-scrollbar-track {
    //     // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //     display: none;

    //   }
    .tracker-items-wrapper {
      user-select: none;

      // height: 100%;
      width: 300%;
      @include d-flex-center;
      .tracker-item-wrapper {
        @include d-inline-flex-column;
        align-items: center;
        width: 33.33333%;
        pointer-events: none;
        min-height: 200px;
        &:first-child .tracker-item-circle-wrapper {
          &:before {
            content: none;
          }
        }
        &:last-child .tracker-item-circle-wrapper {
          &:after {
            content: none;
          }
        }
        .tracker-item-circle-wrapper {
          position: relative;
          @include d-flex-h-center;
          width: 100%;
          max-height: 30px;
          &:before,
          &:after {
            position: absolute;
            top: 15px;
            left: 0;
            @include rtl {
              left: initial;
              right: 0;
            }
            content: ' ';
            width: 50%;
            height: 1px;
            z-index: 1;
            background-color: #a8a8a8;
          }
          &:after {
            left: 50%;
            @include rtl {
              left: initial;
              right: 50%;
            }
          }
          margin-bottom: 1rem;
          .tracker-item-circle {
            @include d-inline-flex-center;
            color: $c-black-light;
            border-radius: 100%;
            position: relative;
            z-index: 2;
            width: 30px;
            min-width: 30px;
            height: 30px;
            background-color: $c-white;
          }
        }
        .tracker-item-text {
          padding: 0.5rem 1rem;
          font-size: $fz-30px;
          // user-select: none;
          font-weight: $fw-bold;
          @include phones-max {
            font-size: $fz-20px;
          }
        }
      }
    }
  }

  .button-container,
  .custom-tour-title-wrapper {
    position: absolute;
    @include d-inline-flex-center;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);

    @include rtl {
      left: initial;
      right: -50%;
      transform: translateX(50%);
    }
  }
  .custom-tour-title-wrapper {
    flex-direction: column;
    bottom: initial;
    top: 20%;
    padding: 0.5rem 1rem;
    @include tablets-max {
      top: 10%;
    }
    color: $c-white;
    font-weight: 300;
    font-size: $fz-22px;
    .second-section {
      font-size: $fz-30px;
    }
    @include phones-s-max {
      left: 0 !important;
      transform: translateX(0) !important;
      right: initial !important;
      width: 100%;
      text-align: center;
      font-size: $fz-19px;
      .second-section {
        font-size: $fz-22px;
      }
    }
  }
}
