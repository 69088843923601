.OfferSection-MAIN {
  position: relative;
  @include d-flex-center;
  padding: 4rem 1rem 1rem;
  .offer-section-content-wrapper {
    @include d-inline-flex-column;
    width: 100%;
    max-width: 1400px;
  }

  .Seaction-Titel-contenar {
    padding: 1rem;
    .seaction-titel {
      color: #501ab7;
      font-size: 20px;
      @include phones-max {
        font-size: 12px;
      }
    }
    .seaction-titel-main {
      color: #2e0e66;
      font-size: 26px;
      @include phones-max {
        font-size: 15px;
      }
    }
  }
  .OfferSection {
    display: flex;
    align-items: center;
    @include phones-max {
      flex-wrap: wrap;
    }

    @include tablets-max {
      padding-bottom: 72px;
    }
    @media screen and (min-width: 2561px) {
      justify-content: flex-end;
    }
    .btnsOffiier.MuiButtonBase-root {
      padding: 0.18rem !important;
      width: 111px;
    }
    .Button-Arrow {
      margin-left: 28%;
      margin-top: 20px;
      @include tablets-max {
        margin-left: 28%;
        margin-top: 3px;
      }
      @include phones-max {
        margin-top: -320px !important;
        margin-left: 40px !important;
        position: absolute;
        display: block;
        width: 300px;
        @include rtl {
          margin-right: 40px !important;
        }
      }
    }
    .favorite {
      z-index: 50;
      color: rgb(255, 255, 255);
      position: absolute;
      margin-left: 31rem;
      padding: 0px;
      @include rtl {
        margin-right: 31rem;
      }
      @include tablets-max {
        margin-left: 15rem;
        @include rtl {
          margin-right: 15rem;
        }
      }
      @include phones-max {
        display: none;
      }
    }
    .favoriticon {
      color: white !important;
      font-size: 7px !important;
    }
    .favoritconclick {
      color: rgb(255, 255, 255) !important;
    }
    .text-from {
      color: white !important;
      font-size: 12px;
      @include tablets-max {
        color: rgb(0, 0, 0) !important;
        display: none;
      }
      @include phones-max {
        display: none;
      }
    }
    .Conteaner {
      position: relative;
      justify-content: center;
      min-height: 500px;
      @include d-inline-flex-h-center;
      width: 100%;
      @include tablets-max {
        min-height: 300px;
      }
      background: #fafafa 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 30px #00000029;
      border: 1px solid #ededed;
      border-radius: 20px;
    }

    .Timer-Seaction {
      // width: 100%;
      @include d-inline-flex-column;
      margin-top: 90px;
      margin-left: 50px;
      @include rtl {
        margin-left: 0;
        margin-right: 50px;
      }
      @include tablets-max {
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
      }
      .time-item-wrapper {
        @include d-inline-flex-column-center;
        color: $c-black;
        font-size: $fz-20px;
        padding: 0.25rem 0.5rem;
        padding-right: 20px;
        padding-left: 20px;
        line-height: 100%;
        position: relative;
        &:after {
          content: '\205A';
          position: absolute;
          right: -0.425rem;
          font-size: 50px;
          top: 57%;
          transform: translateY(-50%);
          @include rtl {
            left: -0.425rem;
            right: initial;
          }
        }
        .time-name {
          color: #9ea0a5;
          font-size: $fz-12px;
        }
      }
      :first-child {
        padding-left: 0;
        @include rtl {
          padding-left: 0.5rem;
          padding-right: 0;
        }
      }
      :nth-last-child(2) {
        padding-right: 0;
        @include rtl {
          padding-right: 0.5rem;
          padding-left: 0;
        }
        &:after {
          content: '';
        }
      }
      .description-name {
        color: #b3a8a8;
        font-size: 14px;
        margin-top: 12px;
        font-weight: 500;
        text-align: start;
        @include tablets-max {
          display: none;
        }
      }
      .Percentage-Num {
        color: #f55200;
        font-size: 20px;
        font-weight: 400;
      }
      .Ends-Offer {
        color: #f55200;
        font-size: 16px;
        font-weight: 500;
      }
      .footer-Timer-Seaction {
        @include d-inline-flex-v-center;
        margin-top: 50px;
        @include phones-max {
          position: absolute;
          right: 0.5rem;
          top: -7rem;
          @include rtl {
            right: initial;
            left: 0.5rem;
          }
        }
      }
    }
    .Gallery-Conteaner {
      .MuiSkeleton-text {
        width: 585px;
        height: 653px;
        border-radius: 20px;
        margin-top: -131px;
        border-bottom-left-radius: 155px !important;
        box-shadow: 4px 7px 13px 7px #8888888f;
        @include rtl {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 100px !important;
        }
        @include tablets-max {
          width: 290px;
          height: 292px;
          margin-top: 0px;
        }
        @include phones-max {
          width: 251px;
          height: 230px;
          border-bottom-left-radius: 99px;
          margin-top: -136px;
          margin-left: 86px;
          @include rtl {
            width: 251px;
            height: 230px;
            margin-top: -136px;
            margin-right: 86px;
          }
        }
      }

      .wr-se {
        width: fit-content;
      }
      .IconButton-conteaner {
        padding-top: 28px;
      }

      .IconButton-conteaner-skelton {
        margin-top: -80px;
        @include phones-max {
          margin-top: 0px;
        }
        @include tablets-max {
          margin-top: -30px;
        }
      }
      width: 50%;
      @include d-inline-flex-column;
      transform: translate(-163px, -124px);
      @media screen and (min-width: 993px) {
        transform: translate(-69px, -124px) !important;
        @include rtl {
          transform: translate(60px, -124px) !important;
        }
      }
      @include phones-max {
        width: 63% !important;
        transform: translate(-56px, -31px) !important;
        @include rtl {
          transform: translate(40px, -31px) !important;
        }
      }
      @include rtl {
        transform: translate(1px, -124px);
      }
      @include tablets-max {
        transform: translate(-42px, -145px);
        @include rtl {
          transform: translate(28px, -125px);
        }
      }
      .Image-contenar {
        background-image: url('http://apigateway.psi-crm.com/FileManager/File/DownloadPublicFile/3b2d144b-e229-4fdd-9f94-755f4e45ce6b');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 580px;
        height: 365px;
        margin-top: 34px;
        border: 0px solid black;
     //   opacity: 39%;

        border-radius: 20px;
        border-bottom-left-radius: 100px !important;
        @include rtl {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 100px !important;
        }
        @include tablets-max {
          width: 321px;
          height: 178px;
        }
        @include phones-max {
          width: 270px;
          height: 178px;
          margin-top: -69px;
          margin-left: 83px;
          @include rtl {
            margin-right: -83px;
            margin-left: 0;
          }
        }
      }

      .Sub-Image-contenar {
        background-image: url('http://apigateway.psi-crm.com/FileManager/File/DownloadPublicFile/39038ae2-3169-47fa-9241-b0871fbf781c');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      //  opacity: 83%;
        position: absolute;
        width: 589px;
        margin-left: -20px;
        height: 386px;
        margin-top: -12px;
        border-radius: 20px;
        border-bottom-left-radius: 100px !important;
        @include rtl {
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 100px !important;
          margin-right: -20px;
          margin-left: 0;
        }
        @include tablets-max {
          width: 331px;
          height: 196px;
        }
        @include phones-max {
          width: 270px;
          height: 196px;
          margin-top: -14px;
          margin-left: 71px;
          @include rtl {
            margin-right: 71px;
            margin-left: 0;
          }
        }
      }
      .First-Image-contenar {
        position: absolute;
        z-index: 50;
        width: 600px;
        margin-left: -45px;
        height: 400px;
        margin-top: -29px;
        border-radius: 20px;
        border-bottom-left-radius: 100px !important;
        box-shadow: 4px 7px 13px 7px #8888888f;
 
        ///////////////////////////////////////////////
        /////////////////////////
        @include rtl
{
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 100px !important;
          margin-right: -45px;
        }
        @include tablets-max {
          width: 344px;
          height: 211px;
        }
        @include phones-max {
          width: 270px;
          height: 195px;
          margin-top: -18px;
          margin-left: 59px;
          @include rtl {
            margin-right: 59px;
            margin-left: 0;
          }
        }
 
      }
      .First-Image-contenar-move {
        animation: animate 0.7s linear infinite;
        position: absolute;
        animation-delay: calc(0.9s * var(--i));
        z-index: 50;
        width: 600px;
        margin-left: -45px;
        height: 400px;
        margin-top: -29px;
        border-radius: 20px;
        border-bottom-left-radius: 100px !important;
        box-shadow: 4px 7px 13px 7px #8888888f;
        -webkit-animation: animate 0.7s linear infinite;
        ///////////////////////////////////////////////
        /////////////////////////
        @include rtl
{
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 100px !important;
          margin-right: -45px;
        }
        @include tablets-max {
          width: 344px;
          height: 211px;
        }
        @include phones-max {
          width: 270px;
          height: 195px;
          margin-top: -18px;
          margin-left: 59px;
          @include rtl {
            margin-right: 59px;
            margin-left: 0;
          }
        }
        @keyframes animate {
          0% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0);
          }

          50% {
            transform: translate3d(-304px, -54px, -107px);
            opacity: 0;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
            -webkit-transform: translate3d(-304px, -54px, -107px);
            -moz-transform: translate3d(-304px, -54px, -107px);
            -ms-transform: translate3d(-304px, -54px, -107px);
            -o-transform: translate3d(-304px, -54px, -107px);
}
          100% {
            transform:translate3d(-200px, -32px, 51px);
            opacity: 0;
            -webkit-transform:translate3d(-200px, -32px, 51px);
            -moz-transform:translate3d(-200px, -32px, 51px);
            -ms-transform:translate3d(-200px, -32px, 51px);
            -o-transform:translate3d(-200px, -32px, 51px);
}
        }
      }
    }
    .none {
      position: absolute;
      top: 0;
      margin-top: 110px;
      z-index: 100;
      @include tablets-max {
        top: 131px;
        left: 64px;
        @include rtl {
          left: 0px;
        }
      }
      @include rtl {
        margin-top: 110px;
        margin-right: 30px;
      }
      @include phones-max {
        margin-left: 32px !important;
        @include rtl {
          margin-right: 32px !important;
          left: -29px;
        }
      }
    }
    .Gallery-container-waraper {
      position: relative;
    }
    .favoriticon {
      z-index: 50;
      color: rgb(255, 255, 255) !important;
    }
    .favoritconclick {
      z-index: 50;
      color: rgb(221, 35, 35) !important;
    }
    .contenar-place-name {
      font-weight: bold;
      @include rtl {
      }
    }
    .text-place-name {
      color: white;
      font-size: 17px;
      font-weight: bolder;

      @include tablets-max {
        color: rgb(0, 0, 0);
      }
      @include phones-max {
      }
    }
    .Rating-Star {
      color: #f5a81d;
      font-size: 22px;
      @include tablets-max {
        margin-left: 20px;
      }
    }
    .Number-Rating-Star {
      color: white;
      font-size: 18px !important;
      font-weight: 100 !important;
      @include tablets-max {
        color: rgb(0, 0, 0);
      }
    }
    .text-name-Distinations {
      font-size: 15px;
      color: white;
      @include rtl {
      }
      @include tablets-max {
        color: rgb(0, 0, 0);
      }
      @include phones-max {
        height: 700px;
      }
    }
    .text-num-Distinations {
      font-size: 15px;
      color: white;
      @include tablets-max {
        color: rgb(0, 0, 0) !important;
      }
    }

    .price-contenar {
      @include phones-max {
        margin-top: -5px;
      }
      @include tablets-max {
        margin-top: -5px;
      }
    }

    .text-old-price {
      font-size: 30px;

      color: #ff7f18;
      text-decoration: line-through;
      @include rtl {
      }
      @include tablets-max {
        font-size: 20px;
        margin-left: 0px;
      }
      @include phones-max {
        margin-left: 10px;
        font-size: 20px;
        @include rtl {
          margin-right: 0px !important;
        }
      }
    }
    .text-new-price {
      margin-left: 15px;
      font-size: 30px;
      font-weight: bold;
      color: white;
      @include rtl {
        // margin-right: 15px !important;
      }
      @include tablets-max {
        color: rgb(0, 0, 0);
        font-size: 25px;
      }
      @include phones-max {
        font-size: 20px;
        color: #2b0649;
        font-weight: 800;
      }
    }

    .price-from-mobile {
      display: none !important;
      @include phones-max {
        display: inline !important;
        margin-left: 0px;
        color: #000000;
        font-size: 12px;
        @include rtl {
          margin-left: 0px !important;
        }
      }
      @include tablets-max {
        display: inline !important;
        padding-right: 10px;
        color: #000000;
        font-size: 12px;
        @include rtl {
          padding-left: 10px !important;
          padding-right: 0px;
        }
      }
    }
  }
}
