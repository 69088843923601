.login-wrapper {
  @include d-flex-column-v-between;
  width: 100%;
  .login-header {
    @include d-flex-column;
    padding: 1rem;
    .title-text {
      opacity: 1;
      font-size: $fz-24px;
      color: $c-white;
    }
  }
  .MuiInputBase-input,
  span {
    opacity: 0.5;
  }

  .login-body {
    padding: 1rem;

    .body-item {
      @include d-flex-center;
      margin-bottom: 0.5rem;
    }
  }
  .login-footer {
    @include d-flex;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    .footer-item {
      @include d-inline-flex-center;
      width: 33.3333%;
      min-height: 45px;
      border-top: 1px solid $c-default-dark-opacity !important;
      &:not(:last-child) {
        border-right: 1px solid $c-default-dark-opacity;

        @include rtl {
          border-left: 1px solid $c-default-dark-opacity;
          border-right: none;
        }
      }
      @include phones-max {
        width: 50%;
        &:nth-child(even) {
          border: none;
        }
        &:last-child {
          width: 100%;
          border: none;
        }
      }
      .btns {
        width: 100%;
        height: 100%;
      }
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
  position: relative;
  .outside-login {
    position: absolute;
    top: 100%;
    @include d-flex-column;
    width: 100%;
    padding: 0.5rem 1rem;
    .outside-item {
      @include d-inline-flex-center;
      @include phones-max {
        flex-direction: column;
      }
    }
    .opacity-1 {
      opacity: 1;
    }
  }
}
