.AllToursCardUiComponentwrapper {
	margin-right: 16px;
	margin-left: 4px;
	$Orange: #f55200;
	$secondary: #2b0649;
	$primary: #002d59;
	$primary-light: #580dbf;
	width: 450px;
	margin-bottom: 40px;
	max-height: 385.26;
	@include phones-max {
		margin-right: 0px;
		margin-left: 0px;
	}
	.Imagewrapper {
		width: 40%;
		height: 20%;
		position: relative;
		.favorite{
			color: #FF5E0D;
		}
	}
	.price-contenar
	{
		margin-top: 10px;
	}
	.trip-contenar
	{
		margin-top: 10px;
		display: flex;
		.name-of-trip{
			width: 60%;
		}
		.ShoppIcon {
			padding-left: 45px;
			@include rtl {
				padding-right: 50px;
				padding-left: 0px;
			}
		}
	}
	.Imagemain {
		position: absolute;
		width: 370.27px;
		height: 186.17px;
		border-radius: 10px;
		z-index: 1;
		margin-left: 23px;
		box-stdow: -1px 7px 11px 1px #888;
		@include rtl {
			margin-right: 23px;
		}
	}
	.Imagesecondary {
		margin-top: 10px;
		border-radius: 10px;
		width: 415.27px;
		opacity: 0.5;
		height: 155px;
	}
	.place-name {
		display: flex;
		position: absolute;
		background: #00000081 0% 0% no-repeat padding-box;
		border-radius: 30px;
		margin-left: 40px;
		margin-top: 15px;
		width:100%;
		z-index: 10;
		height: auto;
		color: white;
		padding-left: 13px;
		padding-right: 11px;
		padding-top: 2.5px;
		@include rtl {
			margin-right: 40px;
		}
		.tripName{
			text-align: justify;
			font-size: 12px;
			width: 80%;
			white-space: nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}
	}
	.hearticon {
		cursor: pointer;
		position: absolute;
		color: white;
		right: -10rem;
		z-index: 10;
		padding-top: 17px;
		@include rtl {
			padding-left: 0px;
			right: 21rem;
		}
	}	

	
	.wrapper-details {
		padding-top: 23.8px;
		text-align: left;
		@include rtl {
			text-align: right;
		}
	}
	.name-of-trip {
		font-size: 1.063rem;
		color: $primary;
		font-weight: bolder;
		white-space: break-spaces
	}
	.from-price {
		font-size: 1.5rem;
		color: $Orange;
		font-weight: bolder;
		padding-top: 6.4px;
		padding-right: 10.8px;
	}
	.btt-See-details {
		width: 90px;
		height: 25px;
		background-color: transparent;
		font-size: 0.75rem;
		font-weight: 600;
		text-transform: inherit;
		color: $primary-light;
		border: 1px solid #501ab7;
		border-radius: 30px;
		appearance: button !important;
		cursor: pointer;
	}
	.About {
		font-size: 0.85rem;
		color: $secondary;
		font-weight: bolder;
		padding-top: 14px;
	}
	.Content {
		padding-top: 6.5px;
		font-size: 0.75rem;
		color: $secondary;
		opacity: 0.72;
	}
	.details {
		display: inline-flex;
		padding-top: 6.5px;
		font-size: 1rem;
		font-weight: bolder;
		color: $secondary;
		.details-Container {
			padding: 0.5rem;
		}
	}
	
	.MuiIconButton-colorPrimary {
		color: #ffffff;
		background-color: $Orange;
	}
	.MuiIconButton-colorPrimary:hover {
		background-color: #f552008f;
	}
}
