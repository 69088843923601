.BestTours-Seaction {
  $colortext: #002d59;
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(
      90deg,
      rgba(255, 127, 24, 1) -15%,
      rgba(255, 19, 100, 1) 43%,
      rgba(43, 6, 73, 1) 300%
    );
  }
  .all-card-container {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .firstcard {
    position: relative;
    @include phones-max {
      min-height: 380px;
    }
    @mixin tablets-max {
      width: 385px;
    }
    padding: 19px;
    width: 100%;
    min-width: 250px;
    min-height: 470px;
  }
  .card-border {
    margin: 15px;
    width: 100%;
    border: 0.18rem solid #e8e8e8;
    border-radius: 22px;
    margin-bottom: 30px;
    margin-top: 30px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
  }
  .btns {
    @include phones-max {
    }
  }
  .card-border-cinter {
    margin: 15px;
    width: 100%;

    border: 0.18rem solid #e8e8e8;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    box-shadow: 4px 4px 13px 0px #8888888f;
    .MuiButton-root {
      text-transform: capitalize;
      border-radius: 20rem;
      @include phones-max {
        margin-top: 1rem;
      }
      color: white;
      font-weight: bold;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background: rgb(255, 127, 24);
      background: linear-gradient(
        90deg,
        rgba(255, 127, 24, 1) -15%,
        rgba(255, 19, 100, 1) 43%,
        rgba(43, 6, 73, 1) 300%
      );
      float: right;
      @include rtl {
        float: left;
      }
      @include phones-max {
        background: white !important;
        background-color: white !important;
        color: #9f4723;
        border-radius: 20rem;
        border: 0.18rem solid #9f4723;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.2rem;
      }
    }
  }
  .favoritehart {
    color: rgb(255, 255, 255);
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    @include rtl {
      left: 3rem;
      top: 1.5rem;
    }
  }
  .isfavoritehart {
    color: #e01f27;
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    @include rtl {
      left: 3rem;
      top: 1.5rem;
    }
  }
  .centercard {
    margin-right: 30px;
    margin-bottom: 40px;
    margin-top: 40px;
    min-height: 522px;
    padding: 25px;
    min-width: 504px;
    border: 0.18rem solid #e8e8e8;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    box-shadow: 0px 0px 17px 3px #888888;
    @include rtl {
      margin-left: 30px;
    }
  }
  .text-big {
    color: $colortext !important;
    font-size: 17px;
    text-align: center;
  }
  .img-center {
    width: 350px;
    height: 292px;
    @include phones-max {
      width: 100%;
      height: 199px;
    }
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
  }
  .text-big-bold {
    color: $colortext !important;
    font-size: 17px;
    font-weight: bold;
    @include d-flex-v-center-h-between;
    .btns {
      @include phones-max {
        border-color: #9f4723;
        background-image: none;
        background-color: transparent;
        color: #9f4723;
      }
    }
  }

  .text-big-bold-center {
    color: $colortext !important;
    font-size: 17px;
    margin-top: 20px;
    font-weight: bold;
  }
  .text-small {
    color: $colortext !important;
    font-size: 14px;
  }
  .textrating {
    font-size: 12px;
    margin-left: 17px;
    @include rtl {
      margin-right: 17px;
    }
  }
  .text-small-rating {
    display: flex;
    color: #002d59;
  }
  .hart {
    position: absolute;
    margin-left: 400px;
    margin-top: 15px;
    font-size: 24px;
    color: #e8e8e8;
    @include rtl {
      margin-right: 400px;
    }
  }

  .contact-field {
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    padding: 2rem;
    @include phones-max {
      display: contents;
    }
  }
  .tiltes-Seaction-continer {
    $text-gree: #676767;
    text-align: center;
    padding-bottom: 15px;
    .main-title {
      color: #501ab7 !important;
      font-size: 35px;
      @include phones-max {
        font-size: 18px;
      }
    }
    .sub-title-1 {
      color: $text-gree !important;
      font-size: 17px;
      width: 400px;
      @include phones-max {
        font-size: 12px;
        width: 245px;
      }
    }
    .sub-title-2 {
      color: $text-gree !important;
      font-size: 12px;
      @include phones-max {
        font-size: 12px;
      }
    }
  }
  .flex-container {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    margin: 10px;
    justify-content: center;
    @include phones-max {
      justify-content: flex-start !important;
    }
    @include laptops-l-max {
      justify-content: flex-start !important;
    }
    @include tablets-max {
      justify-content: flex-start !important;
    }
    font-size: 30px;
  }
  .Arrow-Contenar {
    display: block !important;
    margin-top: 20px;
    margin-right: 11%;
    @include rtl {
      margin-right: 0%;
      margin-left: 11%;
    }
  }
  .bbt-Contenar {
    float: right;
    @include rtl {
      float: left;
    }
  }
}
