.btns {
  &.MuiButtonBase-root {
    @include d-inline-flex-center;
    min-width: 100px;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    font-weight: $fw-medium;
    font-size: $fz-primary;
    color: #fff;
    white-space: nowrap;
    min-height: 30px;
    background-image: $bg-gradient-primary;
    @include rtl {
      background-image: $bg-gradient-primary-reversed;
    }
    &.theme-solid {
      background-image: initial;
      background-color: $c-primary;
      font-size: $fz-14px;
    }
    &.theme-outline {
      border: 1px solid $c-default-dark;
      background-color: transparent;
      background-image: none;
      color: $c-default-dark;
      &.theme-dark {
        border-color: $c-white;
        color: $c-white;
      }
    }
    &.theme-transparent {
      background-color: transparent;
      background-image: none;
      border-color: transparent;
      color: $c-default-dark;
      &.theme-dark {
        color: $c-white;
      }
    }
    &:disabled {
      cursor: not-allowed !important;
      border-color: #c6c6c6;
      background-color: #c6c6c6;
      pointer-events: initial;
      color: #fff;
      .MuiButton-label {
        color: #fff;
      }
    }
  }
}
.btns-icons {
  &.MuiButtonBase-root {
    @include d-inline-flex-center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 100%;
    color: $c-primary;
    font-weight: $fw-medium;
    font-size: $fz-primary;
    background-color: #f5f5f5;
    &.theme-transparent {
      background-color: transparent;
      &.theme-dark {
        color: $c-white;
      }
    }
    &.theme-outline {
      background-color: transparent;
      border: 1px solid $c-primary;
      &.theme-dark {
        color: $c-default-dark;
        border-color: $c-default-dark;
      }
      &:disabled {
        background-color: transparent;
        color: #c6c6c6;
        .MuiButton-label {
          color: #c6c6c6;
        }
      }
    }
    &:disabled {
      cursor: not-allowed !important;
      border-color: #c6c6c6;
      background-color: #c6c6c6;
      pointer-events: initial;
      color: #fff;
      .MuiButton-label {
        color: #fff;
      }
    }
  }
}
