.popover-wrapper {
  .MuiPaper-root {
    min-width: 300px;
    border-radius: 1rem;
  }
  &.theme-dark {
    .MuiPopover-paper {
      background-color: $c-default-dark;
    }
  }
  &.with-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
