.select-wrapper {
  &.MuiFormControl-root {
    min-width: 270px;
    &.with-start-andorment {
      .MuiInputBase-adornedStart > .mdi {
        color: $c-default-dark;
        margin-left: 0.5rem;
        @include rtl {
          margin-right: 0.5rem;
          margin-left: initial;
        }
      }
      &.theme-dark {
        .MuiInputBase-adornedStart > .mdi {
          color: $c-white;
        }
      }
    }
    .label-wrapper {
      padding: 0 0.5rem 0.25rem;
      color: $c-default-dark;
      width: 100%;
    }
    .select-body-wrapper {
      @include d-flex;
      flex: 0 0 auto;
      position: relative;
      width: 100%;
      .MuiInput-underline.Mui-error:after {
        display: none;
      }
      .dropdown-icon-wrapper {
        @include d-inline-flex-center;
        color: $c-default-dark;
        font-size: $fz-22px;
        margin: 0 0.45rem;
        &::before {
          font-size: $fz-22px;
        }
      }
      .selects {
        border-radius: 2rem;
        background-color: $c-white;
        width: 100%;
        min-height: 35px;
        border: 1px solid #e6e6e6;
        &.MuiInput-underline::before,
        &.MuiInput-underline.Mui-focused:after {
          display: none;
        }
        &:hover:not([disabled]):not(.Mui-disabled) {
          border-color: #dbf5ee;
        }
        &.Mui-focused:not([disabled]):not(.Mui-disabled) {
          border-color: $c-default-dark !important;
          box-shadow: 0 0 2px 2px rgba($c-default-dark, 0.3);
        }
        &.Mui-error {
          border-color: $c-error !important;
          box-shadow: 0 0 2px 2px rgba($c-error, 0.5);
        }
        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-default-dark;
        }
        .MuiSelect-select.MuiSelect-select {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          min-height: 20px;
          color: $c-default-dark;
        }
        .MuiSelect-select {
          background-color: transparent !important;
        }
      }
    }
    &.theme-dark {
      .label-wrapper {
        color: $c-white;
      }
      .select-body-wrapper {
        .dropdown-icon-wrapper {
          color: $c-white;
        }
        .selects {
          background-color: $c-default-dark-opacity;
          border: 1px solid $c-default-dark-opacity;
          &:hover:not([disabled]):not(.Mui-disabled) {
            border-color: $c-default-dark;
          }
          &.Mui-focused:not([disabled]):not(.Mui-disabled) {
            border-color: $c-white !important;
            box-shadow: 0 0 2px 2px rgba($c-white, 0.3);
          }
          &.Mui-error {
            border-color: $c-error !important;
            box-shadow: 0 0 2px 2px rgba($c-error, 0.5);
          }
          .MuiSelect-icon:not(.Mui-disabled) {
            fill: $c-white;
          }
          .MuiSelect-select.MuiSelect-select {
            color: $c-white;
          }
        }
      }
    }
  }
}
// MuiMenuItem-root
.select-menu-wrapper {
  .MuiMenu-paper {
    border-radius: 1rem;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    .MuiMenu-list {
      .MuiMenuItem-root {
        // background-color: transparent;
        color: $c-default-dark;
      }
    }
  }
  &.theme-dark {
    .MuiMenu-paper {
      background-color: $c-default-dark;
      border: 1px solid $c-default-dark;
      .MuiMenu-list {
        .MuiMenuItem-root {
          &[aria-selected='true'] {
            background-color: #4b0f7c !important;
          }
          color: $c-white;
        }
      }
    }
  }
}
