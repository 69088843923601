.User-Component {
  padding-top: 7rem;
  display: inline-block;
  width: 100%;
}
.UploadImageView {
  display: flex;
  justify-content: center;
  width: 89%;
  @include tablets-max {
    width: 70%;
  }
}
.Userswrapper {
  .SectionView {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .UploaderComponentCircularwrapperClasses {
      display: block;
      position: relative;
    }
  }

  $Orange: #f55200;
  $secondary: #2b0649;
  $primary: #002d59;
  $primary-light: #580dbf;
  padding-top: 1rem;
  padding-bottom: 5rem;
  width: 78%;
  margin: auto;
  .card-wraper {
    padding: 1rem;
    width: 427px;
    min-height: 130px;
    border: 1px solid #ededed;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .body-item {
      @include d-flex-center;
      margin-bottom: 0.5rem;
    }
    .hide {
      color: white;
    }
    .footer-wraper {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
      .mdi {
        color: #501ab7;
      }
    }
    .description-wraper {
      color: #002d59;
    }
    .detales-conteaner {
      font-size: 12px;
    }
    .btns.MuiButtonBase-root:disabled {
      background-image: none !important;
    }
  }
  .AccountDetails {
    display: block;
    margin-top: 2rem;
    .input-wrapper .label-wrapper {
      padding: 0 0.5rem 0.25rem;
      color: #2b0649;
      width: 100%;
      font-size: 12px;
    }
    .d-flex-center {
      margin-top: 2px;
    }
    .phones-wrapper.theme-default .react-tel-input .form-control {
      width: 100%;
      background-color: #fafafa;
      border: 1px solid #f0f0f0;
      color: #2b0649;
      border-radius: 2rem;
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
    .phones-wrapper .flag-dropdown {
      background-color: #fafafa;
      border: 1px solid #f0f0f0;
      border-radius: 2rem 0rem 0rem 2rem;
      -webkit-border-radius: 2rem 0rem 0rem 2rem;
      -moz-border-radius: 2rem 0rem 0rem 2rem;
      -ms-border-radius: 2rem 0rem 0rem 2rem;
      -o-border-radius: 2rem 0rem 0rem 2rem;
    }
  }
}
