.MyTours {
  padding: 5rem;
  .opation-wraper {
    width: 100%;
    margin-bottom: 1.5rem;
    .opation {
      margin: 0 10px 0 10px;
      width: 26rem;
      display: flex;
      justify-content: center;
      color: #580dbf;
      .opation-bbt {
        border-bottom: 1px solid #707070;
        padding: 0.7rem;
        width: 100%;
        &.isActive {
          border-bottom: 3px solid $c-primary !important;
          color: $c-primary;
        }
      }
    }
  }
}
.CardMyToursComponentes {
  padding-top: 10rem;
  display: flex;
  justify-content: center;
  .card-wraper {
    width: 432px;
    height: 572px;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 20px;
    margin: 1rem;
    &.active {
      border: 1px solid $c-primary;
      border-radius: 20px;
    }
    @include phones-max {
      width: 400px;
    }
    @include phones-xs-max {
      width: 339px;
    }
    @include phones-xs-max {
      width: 200px;
    }
    .card-ineer-wraper {
      @include phones-xs-max {
        padding: 12px;
      }
      padding: 2rem;

      .main-text {
        font-size: 19px;
        color: black;
        font-weight: bolder;
      }
      .Properties-wraper {
        font-size: 14px;
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        .Properties {
          .details {
            min-width: 98px;
            height: 30px;
            border-radius: 15px;
            background: #f9f9f9;
            margin: 0.5rem;
            font-size: 12px;
            padding: 0.5rem;
            align-items: center;
            display: flex;
            color: black;
            justify-content: space-between;
          }
        }
        @include phones-xs-max {
          flex-wrap: wrap;
        }
      }
      .Google-map-wraper {
        display: flex;
        justify-content: center;
        .GoogleMapReact {
          width: 356px;
          display: flex;
          height: 86px;
          justify-content: center;
          align-items: center;
          div {
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
          }
        }
        .gm-style .gm-style-cc a,
        .gm-style .gm-style-cc button,
        .gm-style .gm-style-cc span,
        .gm-style .gm-style-mtc div {
          font-size: 0;
          box-sizing: border-box;
        }
      }
      .Pickup {
        color: #580dbf;
        font-size: 12px;
        align-items: center;
        display: flex;
        padding-top: 0.7rem;
        font-weight: bolder;
        &.off {
          color: $c-primary;
        }
      }

      .Google-map-mark {
        width: 28px;
        height: 28px;
        background-color: #580dbf;
        border-radius: 28px;
        align-items: center;
        justify-content: center;
        .mdi {
          color: white !important;
        }
        &.off {
          background-color: $c-primary;
        }
      }
    }
  }
}

////////////////////////
.CardMyToursPastComponentes {
  padding: 10rem;
  .bbt-review {
    min-width: 119px;
    min-height: 38px;
    background-color: transparent;
    border: 1px solid $c-primary;
    border-radius: 20px;
    padding: 0.4rem;
  }
  .main-header {
    display: flex;
    color: #2b0649;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
  }
  .text-def {
    font-size: 12px;
    color: #2b0649;
  }
  .card-wraper {
    width: 432px;
    height: 457px;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 20px;
    margin: 1rem;
    &.active {
      border: 1px solid $c-primary;
      border-radius: 20px;
    }
    @include phones-max {
      width: 400px;
    }
    @include phones-xs-max {
      width: 339px;
    }
    @include phones-xs-max {
      width: 200px;
    }
    .card-ineer-wraper {
      @include phones-xs-max {
        padding: 12px;
      }
      padding: 2rem;

      .main-text {
        font-size: 19px;
        color: black;
        font-weight: bolder;
      }
      .Properties-wraper {
        font-size: 14px;
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        .Properties {
          .details {
            min-width: 98px;
            height: 30px;
            border-radius: 15px;
            background: #f9f9f9;
            margin: 0.5rem;
            font-size: 12px;
            padding: 0.5rem;
            align-items: center;
            display: flex;
            color: black;
            justify-content: space-between;
          }
        }
        @include phones-xs-max {
          flex-wrap: wrap;
        }
      }
      .Google-map-wraper {
        display: flex;
        justify-content: center;
        .GoogleMapReact {
          width: 356px;
          display: flex;
          height: 86px;
          justify-content: center;
          align-items: center;
          div {
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
          }
        }
        .gm-style .gm-style-cc a,
        .gm-style .gm-style-cc button,
        .gm-style .gm-style-cc span,
        .gm-style .gm-style-mtc div {
          font-size: 0;
          box-sizing: border-box;
        }
      }
      .Pickup {
        color: #580dbf;
        font-size: 12px;
        align-items: center;
        display: flex;
        padding-top: 0.7rem;
        font-weight: bolder;
        &.off {
          color: $c-primary;
        }
      }

      .Google-map-mark {
        width: 28px;
        height: 28px;
        background-color: #580dbf;
        border-radius: 28px;
        align-items: center;
        justify-content: center;
        .mdi {
          color: white !important;
        }
        &.off {
          background-color: $c-primary;
        }
      }
    }
  }
}

.container-data {
  display: flex;
  padding: 1rem 0rem 0.5rem 0rem;
  color: #2b0649;
  font-size: 12px;
  &.isPastComponentes {
    justify-content: center;
  }

  .details-parent {
    display: inline-block;
    width: 100%;
  }
  .details-seaction {
    display: flex;
    width: 74%;
    height: 5rem;
    .details-wraper {
      width: 70%;
    }
  }
  .Image-container {
    .isPastComponentesImage {
      width: 333px;
      height: 130px;
      border-radius: 10px;
    }
    .Image {
      width: 101px;
      height: 54px;

      border-radius: 10px;
    }
  }
  .details {
    .mdi:before {
      font-size: 14px;
    }
  }
  .timeline {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 8%;
  }

  .line {
    height: 35px;
    width: 2px;
    background: #d1d6e6;
  }

  .dot {
    width: 6px;
    height: 6px;
    background: $c-primary;
    border-radius: 7px;
  }

  .dot-num {
    border: 1px solid #e3e3e3;
    width: 18px;
    color: black;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    height: 18px;
    border-radius: 25px;
  }
}

.PastComponentestimeline {
  .timeline {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
  }

  .line {
    height: 2px;
    width: 20%;
    background: #d1d6e6;
  }

  .dot {
    width: 6px;
    height: 6px;
    background: $c-primary;
    border-radius: 7px;
  }
  .dot-num {
    border: 1px solid #e3e3e3;
    width: 18px;
    color: black;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    height: 18px;
    border-radius: 25px;
  }
}

.EmptyPastComponentesViews {
  .text-def {
    color: #580dbf;
  }
  justify-content: space-around;
  display: inline-flex;
  flex-direction: row;
  padding: 7rem;
  width: 100%;
  align-items: center;
  .PastToursbbt {
    text-transform: capitalize;
    width: 158px;
    height: 38px;
    color: #501ab7;
    background: #ffffff;
    border: 1px solid #501ab7;
    border-radius: 19px;
  }
  .PastTours-dec-Contral {
  }
  .ImageNoPastTours {
    width: 80%;
    height: 100%;
  }
  .wraer-actions {
    font-size: 12px;
    margin-top: 15rem;
  }
  .text-title-Past {
    padding: 0rem 0rem 1rem 0rem;
    text-transform: capitalize;
    &.free {
      padding: 0rem 0rem 0rem 0rem;
    }
    &.maintext-big {
      font-weight: bold;
      font-size: 17px;
    }
  }
}
