.filter-box {
  height: 440px;
  width: 400px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;

  .by-city {
    border-bottom: inset;
    .filterby {
      text-align: left;
      font-size: 18px;
      color: #002d59;
      margin: 10px;
      display: block;
      @include rtl{
        text-align: right;
      }
      .MuiInput-root {
        width: 100%;
      }
      .selected {
        display: flex;
        justify-content: space-between;
        .section {
          width: 100%;
        }
      }
    }
  }
  .by-Tour {
    border-bottom: inset;
    .filterby {
      @include rtl{
        text-align: right;
      }
      text-align: left;
      font-size: 18px;
      color: #002d59;
      margin: 10px;
      display: block;
    }
  }
  
  .by-times {
    border-bottom: inset;
    .filterby {
      @include rtl{
        text-align: right;
      }
      text-align: left;
      font-size: 18px;
      color: #002d59;
      margin: 10px;
      display: block;
      .time-range {
        font-size: 13px;
        color: #002d59;
        margin: 10px;
      }
      .time-slider {
        width: 96%;
        margin: 5px;
      }
    }
  }
}
